import React, { useState, useEffect, useRef } from "react";
import "./ThumbnailVideoPlayer.css"; // Import your CSS
import { Link, useNavigate } from "react-router-dom";

const ThumbnailVideoPlayer = ({ thumbnails }) => {
  const navigate = useNavigate();
  const [visibleVideos, setVisibleVideos] = useState({});
  const videoRefs = useRef([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [hoveredVideoIndex, setHoveredVideoIndex] = useState(null);
  const [turnOnAutoVideoPlay, setTunOnAutoVideoPlay] = useState(1);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      const index = entry.target.dataset.index;

      if (entry.isIntersecting) {
        // Thumbnail is visible, play the video
        setVisibleVideos((prev) => ({ ...prev, [index]: true }));
      } else {
        // Thumbnail is out of view, pause the video
        setVisibleVideos((prev) => ({ ...prev, [index]: false }));
      }
    });
  };
  const playRandomVideo = () => {
    console.log("Play Random Video");
    const visibleIndexes = Object.keys(visibleVideos).filter(
      (index) => visibleVideos[index]
    );
    if (visibleIndexes.length > 0) {
      const randomIndex =
        visibleIndexes[Math.floor(Math.random() * visibleIndexes.length)];
      setCurrentVideoIndex(randomIndex); // Set the new video to play
    }
  };

  const handleMouseEnter = (index) => {
    console.log("Mouse Enter");
    setCurrentVideoIndex(null);
    setTunOnAutoVideoPlay(0);
    setHoveredVideoIndex(index); // Set the hovered video to be played
  };

  const handleMouseLeave = () => {
    console.log("Mouse Leave");
    setHoveredVideoIndex(null); // Reset hovered video
    setTunOnAutoVideoPlay(1);
  };
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       const randomIndex = Math.floor(Math.random() * thumbnails.length);
  //       setCurrentVideoIndex(randomIndex);
  //     }, 5000); // Change video every 5 seconds

  //     return () => clearInterval(interval); // Cleanup on component unmount
  //   }, [thumbnails.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // relative to the viewport
      rootMargin: "0px", // Margin around the viewport to trigger early detection
      threshold: 0.5, // Trigger when 50% of the thumbnail is visible
    });

    // Observe each thumbnail
    videoRefs.current.forEach((ref) => observer.observe(ref));

    return () => {
      // Cleanup observers on unmount
      observer.disconnect();
    };
  }, [thumbnails]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Auto Video Play Status ", turnOnAutoVideoPlay);
      if (turnOnAutoVideoPlay === 1) {
        console.log("Auto Video Play turned on...Play next");
        playRandomVideo();
      } else {
        console.log("Auto video play turned off");
      }
    }, 5000); // Change video every 5 seconds (or adjust as needed)

    return () => clearInterval(interval);
  }, [visibleVideos, turnOnAutoVideoPlay]);

  console.log("Visible Thumbnails", visibleVideos);

  return (
    <div className="row gx-3 mb-3 mt-3">
      {thumbnails.map((thumbnail, index) => (
        <div className="col-6 col-sm-2 position-relative">
          <div
            className="gameItem"
            key={index}
            onMouseEnter={() => handleMouseEnter(index)} // Trigger on hover
            onMouseLeave={handleMouseLeave}
          >
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/game/${thumbnail?.id}/${thumbnail?.title}`, {
                  state: {
                    datas: thumbnail,
                    flag: "home",
                  },
                })
              }
              src={thumbnail.cover_picture}
              alt={`Thumbnail ${index}`}
              className="connect_the_ball_ek11 gameThumb"
            />
            {/* {currentVideoIndex === index && (
            <video
              src={thumbnail.video}
              autoPlay
              muted
              loop
              className="thumbnail-video"
            />
          )} */}

            <div
              ref={(el) => (videoRefs.current[index] = el)}
              data-index={index}
            >
              {visibleVideos[index] &&
                (currentVideoIndex === String(index) ||
                  hoveredVideoIndex === index) && (
                  <video
                    src={thumbnail?.video_url || thumbnail?.video_upload}
                    autoPlay
                    muted
                    loop
                    className="thumbnail-video"
                  />
                )}
            </div>
            <div className="hoverText">{thumbnail?.title}</div>
          </div>

          {thumbnail?.tag == "new" && <div className="new-badge">New</div>}
          {thumbnail?.last_updated && (
            <div className="update-badge">Updated</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ThumbnailVideoPlayer;
