import React, { useEffect } from "react";
import Cookies from "universal-cookie";
const cookie_reset = () => {
  let cookies = new Cookies();
  //   useEffect(() => {
  cookies.remove("token", { path: "/" });
  cookies.remove("network_details", { path: "/" });
  cookies.remove("login_token", { path: "/" });
  //   }, []);

  return <div style={{ color: "#fff" }}>cookie reset successfully.</div>;
};

export default cookie_reset;
