import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home_screen/home.jsx";
import Game from "./game_screen/game_screen.jsx";
import Game_stack from "./game_stack/game_stack.jsx";
import "rsuite/dist/rsuite-no-reset.min.css";
import Cookies from "universal-cookie";
import axios from "axios";
import Service from "./service/apis.js";
import Cookie_reset from "./cookie_reset.jsx";
import Policy from "./t&c/policy.jsx";
import Terms from "./t&c/terms.jsx";
import Leaderboard from "./leaderboard/leaderboard.jsx";
import { Modal, Drawer, Button, HStack } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Divider, Input, Avatar } from "rsuite";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Radio, RadioGroup } from "rsuite";
import moment from "moment";
import {
  Uploader,
  Message,
  Loader,
  useToaster,
  SelectPicker,
  AvatarGroup,
  DatePicker,
} from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { alert, confirm, prompt } from "@rsuite/interactions";
import OtpInput from "react-otp-input";
import Avatar_data from "./avatars.json";
import { IoCamera } from "react-icons/io5";
import ImageIcon from "@rsuite/icons/Image";
import { TiTick } from "react-icons/ti";
import { analytics } from "./firebase.js";
import { logEvent } from "firebase/analytics";
import useStore from "./service/zustand.js";
import History from "./history/history.jsx";

let BASE_URL = process.env.REACT_APP_BASE_URL_V2;

const Profile_Modal = NiceModal.create(({ full_name, file, preview }) => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  const toaster = useToaster();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [err, setErr] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [dob_status, setDob_status] = useState(true);
  const [name_status, setName_status] = useState(true);

  // Function to handle avatar selection
  const handleAvatarClick = (index) => {
    console.log({ index });
    setSelectedAvatar(index);
  };
  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const onContactFromSubmit = async () => {
    setLoading(true);
    if (!name) {
      setErr(true);
      setLoading(false);
      return;
    }
    setErr(false);
    let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
    let form = new FormData();
    form.append("full_name", name);
    form.append("profile_picture", file || fileList);
    form.append("date_of_birth", dob || "");
    form.append("gender", selectedGender || "");
    form.append("avatar_id", selectedAvatar || "");
    try {
      await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
        method: "POST",
        body: form,
        headers: {
          //  'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status == 0) {
            setLoading(false);
          }
          if (data.status == 1) {
            setLoading(false);
            modal.hide();

            window.location.reload(true);
          }
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const handleUsername = (event) => {
    setName(event);
  };

  useEffect(() => {
    setName(full_name);
  }, []);

  const genders = [
    "Male",
    "Female",
    "Non-Binary",
    "Other",
    "Prefer not to say",
  ];

  // State to track the selected gender
  const [selectedGender, setSelectedGender] = useState("");

  // Handle change in selected gender
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 1960; year <= currentYear; year++) {
    years.push(year);
  }
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleYearChange = (event) => {
    console.log(event.target.value);
    setSelectedYear(event.target.value);
  };

  const days = Array.from({ length: 31 }, (_, index) => index + 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <h4 className="mb-3 fw-normal">Update Profile </h4>
          <p
            className="text-center mb-0"
            style={{ fontSize: "14px", color: "#818A97", whiteSpace: "normal" }}
          >
            This information is shared exclusively with
            <br />
            Thinktrek and will not be visible on your profile.
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex flex-row flex-wrap ">
          <button
            style={{
              background: "#F2641F",
              width: 40,
              height: 40,
              borderRadius: 50,
              border: "none",
              margin: "0 5px",
              marginBottom: "10px",
              padding: 0,
            }}
          >
            {!preview ? (
              <IoCamera
                onClick={() => {
                  NiceModal.show(Camera_Modal, {
                    full_name,

                    file,
                    preview,
                  });
                  modal.hide();
                }}
                style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
              />
            ) : (
              <img
                style={{ borderRadius: 35, width: 40, height: 40 }}
                onClick={() => {
                  NiceModal.show(Camera_Modal, {
                    full_name,
                    file,
                    preview,
                  });
                  modal.hide();
                }}
                src={preview}
                width="100%"
                height="100%"
              />
            )}
          </button>

          <Uploader
            onChange={(event) => setFileList(event[0].blobFile)}
            fileListVisible={false}
            listType="picture"
            action="/"
            onUpload={(file) => {
              setUploading(true);
              previewFile(file.blobFile, (value) => {
                setFileInfo(value);
                //console.log(value);
              });
            }}
          >
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "5px",
                marginBottom: "10px",
              }}
            >
              {fileInfo ? (
                <img src={fileInfo} width="100%" height="100%" />
              ) : (
                <ImageIcon style={{ fontSize: 15, color: "#fff" }} />
              )}
            </button>
          </Uploader>

          {Avatar_data.map((item, key) => {
            return (
              <AvatarGroup spacing={6} key={key} className="position-relative">
                <Avatar
                  color="#2ED573"
                  // bordered
                  key={key}
                  onClick={() => {
                    handleAvatarClick(item.avatar_id);
                  }}
                  style={{
                    cursor: "pointer",
                    margin: "0 5px",
                    marginBottom: "10px",
                  }}
                  circle
                  src={require(`../src/assets/avatars/${item.avatar_icon}`)}
                  alt=""
                />
                {selectedAvatar === item.avatar_id && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      bottom: "11px",
                      right: "0",
                      width: "13px",
                      height: "13px",
                      borderRadius: "3px",
                      background: "#2ED573",
                      position: "absolute",
                    }}
                  >
                    <TiTick
                      style={{ fontSize: "10px" }}
                      className="text-white"
                    />
                  </div>
                )}
              </AvatarGroup>
            );
          })}
        </div>
        <div className="editField" style={{ marginTop: 10 }}>
          <label className="text-white">Username*</label>
          <Input
            disabled={name_status ? true : false}
            value={name}
            placeholder="Fill username"
            onChange={handleUsername}
            style={{ background: "none" }}
          />
          {err && (
            <span style={{ fontSize: "10px", color: "red" }}>
              Enter username
            </span>
          )}
          <span
            className="editLink d-flex align-items-center"
            onClick={() => setName_status(false)}
          >
            Edit
          </span>
        </div>
        <div className="editField" style={{ marginTop: 10 }}>
          <label className="text-white d-block">
            Date of birth
            <img
              className="ms-2"
              src={require("../src/assets/info-icon.png")}
              width={15}
              alt=""
            />
          </label>
          <DatePicker
            disabled={dob_status ? true : false}
            oneTap
            className="w-100"
            onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
          />
          <span
            className="editLink d-flex align-items-center"
            onClick={() => setDob_status(false)}
          >
            Edit
          </span>
        </div>
        <div className="editField" style={{ marginTop: 10 }}>
          <label className="text-white">Gender</label>
          <select
            className="w-100"
            id="gender"
            name="gender"
            value={selectedGender}
            onChange={handleGenderChange}
          >
            <option value="">Select Gender</option> {/* Default empty option */}
            {genders.map((gender, index) => (
              <option key={index} value={gender}>
                {gender}
              </option>
            ))}
          </select>
        </div>
        <div className="text-center">
          {!file &&
          !selectedAvatar &&
          !selectedDate &&
          !selectedMonth &&
          !selectedYear &&
          !selectedGender &&
          !fileList ? (
            <Button
              onClick={() => {
                onContactFromSubmit();
                // modal.hide();
              }}
              className="guest-btn w-auto"
              style={{ borderRadius: 20, padding: "10px 25px" }}
              block
            >
              Skip for Now
            </Button>
          ) : (
            <Button
              className="w-auto"
              onClick={onContactFromSubmit}
              loading={loading}
              appearance="primary"
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
              }}
              block
            >
              Save & Continue
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
});

const Phone_Modal = NiceModal.create(({ id, name }) => {
  const modal = useModal();
  let cookies = new Cookies();
  const [phone, setPhone] = useState("");
  const [dial, setDial] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  let validate_users = cookies.get("token");
  let IP_URL = process.env.REACT_APP_IP_URL;
  let [offline_data, setOffline_data] = useState({
    ip: "122.176.24.171",
    network: "122.176.24.0/24",
    version: "IPv4",
    city: "Kolkata",
    region: "West Bengal",
    region_code: "WB",
    country: "IN",
    country_name: "India",
    country_code: "IN",
    country_code_iso3: "IND",
    country_capital: "New Delhi",
    country_tld: ".in",
    continent_code: "AS",
    in_eu: false,
    postal: "700054",
    latitude: 22.518,
    longitude: 88.3832,
    timezone: "Asia/Kolkata",
    utc_offset: "+0530",
    country_calling_code: "+91",
    currency: "INR",
    currency_name: "Rupee",
    languages:
      "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    country_area: 3287590,
    country_population: 1352617328,
    asn: "AS24560",
    org: "Bharti Airtel Ltd., Telemedia Services",
  });

  const guest_user = () => {
    if (!validate_users) {
      fetch_user_details();
    }
  };
  const fetch_user_details = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        console.log({ res });
        cookies.set("network_details", res.data, { path: "/" });
        let data = res.data;
        validate_user(data);
      })
      .catch((e) => {
        console.log(e.config.data);
        if (e.config.data === undefined) {
          validate_user(offline_data);
        }
      });
  };

  const validate_user = (data) => {
    let params = {
      ip_address: data.ip,
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
      additional_details: JSON.stringify(data),
    };
    Service.login_register_visitor(params)
      .then((res) => {
        // console.log(res);
        logEvent(analytics, "guest_user_count", {
          country: data?.country_name,
          city: data?.city,
        });
        cookies.set("token", res.token, { path: "/" });
      })
      .catch((e) => console.log(e.message));
  };
  const fetch_user_network = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        cookies.set("network_details", res.data, { path: "/" });
      })
      .catch((e) => {
        // console.log(e.config.data)
        if (e.config.data === undefined) {
          // validate_user(offline_data)
        }
      });
  };
  const handleInputChange = (value, data) => {
    //const inputValue = value
    // console.log(value)
    let code = `+${data.dialCode}`;
    setDial(code);
    let number = value.toString().slice(data.dialCode.length);
    // console.log(number?.length)
    //  console.log(value.toString().slice(data.dialCode.length))
    setPhone(number);
    //console.log(value.split(' ')[1]);
  };
  let network = cookies.get("network_details");
  const onContinue = async () => {
    setLoading(true);
    let params = {
      phone_number: phone,
    };
    await Service.login_number(params)
      .then((res) => {
        // console.log(res.otp)
        if (res.status == 0) {
          setLoading(false);
        }
        if (res.status == 1) {
          if (!network) {
            fetch_user_network();
          }
          setLoading(false);
          modal.hide();
          NiceModal.show(Otp_Modal, {
            number: phone,
            otp_code: res.otp,
            dial_code: dial,
          });
        }
      })
      .catch((e) => console.log(e.message));
  };
  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <h4 className="mb-4 fw-normal">Log In</h4>
          <p className="mb-0" style={{ fontSize: "16px" }}>
            Welcome to the game portal
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <PhoneInput
          className="phone"
          copyNumbersOnly={true}
          //autoFormat={false}
          prefix="+"
          countryCodeEditable={false}
          placeholder="Fill mobile number"
          country={"in"}
          //  value={phone}
          onChange={(value, data, event, formattedValue) =>
            handleInputChange(value, data)
          }
        />
        <Button
          className="my-4"
          loading={loading}
          onClick={() => {
            onContinue();
          }}
          disabled={!phone ? true : false}
          appearance="primary"
          style={{
            width: "auto",
            marginTop: 10,
            borderRadius: 20,
            background: !phone ? "#818A97" : "#F2641F",
            padding: "10px 15px",
          }}
        >
          Play Now
        </Button>
        <p
          className="text-secondary"
          style={{ justifyItems: "flex-end", flex: 1 }}
        >
          OR
        </p>
        <div style={{ marginTop: 10 }}></div>
        <Button
          className="guest-btn"
          onClick={() => {
            guest_user();
            modal.hide();
            // window.location.reload(true);
          }}
          // appearance="ghost"
          style={{ marginTop: 10, borderRadius: 20, width: "auto" }}
          block
        >
          Continue as Guest
        </Button>
        <hr />
        <p
          className="text-white text-start m-0"
          style={{ fontSize: "12px", color: "#b5b5b5 !important" }}
        >
          Note: As a guest you are agreeing to the terms that you are 13+ years
          of age. To get the advantages and special privilages of partcipating
          in the Weekly Leaderboard Challenges, you need to Login.
        </p>
      </Modal.Body>
    </Modal>
  );
});

const Otp_Modal = NiceModal.create(({ number, otp_code, dial_code }) => {
  const modal = useModal();
  let cookies = new Cookies();
  const [phone, setPhone] = useState("");
  const [dial, setDial] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  let [msg, setMsg] = useState("");
  let network = cookies.get("network_details");
  const { profile_status_load, add_profile_load } = useStore();
  const verifyCode = async () => {
    setLoading(true);
    let params = {
      phone_number: number,
      otp: otp,
      ipAddress: network?.ip,
      country: network?.country_name,
      city: network?.city,
      latitude: network?.latitude,
      longitude: network?.longitude,
    };
    await Service.verify_otp(params)
      .then((res) => {
        //console.log(res.data.visitorDetails.full_name);
        if (res.status == 0) {
          setMsg(res?.message);
          setLoading(false);
        }
        if (res.status == 1) {
          if (res?.data?.profile_completion_status == false) {
            logEvent(analytics, "registered_user_count", {
              country: network?.country_name,
              city: network?.city,
            });
            cookies.set("login_token", res.token, { path: "/" });
            modal.hide();
            NiceModal.show(Profile_Modal, {
              full_name: res?.data?.visitorDetails?.full_name,
            });
            setLoading(false);
          }
          add_profile_load(true);
          cookies.set("login_token", res.token, { path: "/" });
          modal.hide();
          setMsg("");
          ///window.location.reload();
          //NiceModal.show(Profile_Modal, { id: 'game_id', })
          setLoading(false);
        }
      })
      .catch((e) => console.log(e.message));
  };

  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <h4 className="mb-4 fw-normal">Verify</h4>
          <p
            className="mb-0"
            style={{ fontSize: "16px", whiteSpace: "normal" }}
          >
            {`Please enter your verification code sent to ${dial_code}${number}`}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p style={{ color: "red" }}>{msg}</p>
        <OtpInput
          inputType="tel"
          value={otp}
          onChange={setOtp}
          numInputs={5}
          containerStyle={{
            margin: "5px",
            justifyContent: "center",
          }}
          inputStyle={{
            height: "40px",
            width: "40px",
            margin: "5px",
            background: "#333947",
            color: "#F5F9FF",
            borderRadius: 5,
            border: "none",
          }}
          // renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
        <p className="text-white m-0">{otp_code}</p>

        <Button
          disabled={otp.length == 5 ? false : true}
          loading={loading}
          onClick={() => {
            verifyCode();
            // modal.hide()
            // NiceModal.show(Profile_Modal)
          }}
          //#818A97
          // appearance="primary"
          style={{
            marginTop: 25,
            borderRadius: 20,
            padding: "10px 40px",
            background: otp.length == 5 ? "#F2641F" : "#818A97",
            color: otp.length == 5 ? "#fff" : "#ffffff47",
          }}
        >
          Verify
        </Button>
      </Modal.Body>
    </Modal>
  );
});

const Camera_Modal = NiceModal.create(({ full_name, file, preview }) => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  const videoRef = useRef(null); // To reference the video element
  const canvasRef = useRef(null); // To reference the canvas element
  const streamRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setStream(stream); // Store the stream
          setHasPermission(true);
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
        setHasPermission(false);
      }
    };

    // Start the camera when the component mounts
    startCamera();

    // Cleanup the stream on component unmount
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        // const tracks = stream.getTracks();
        // tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop each track
      // setIsCameraOn(false); // Update the camera status
    }
    setHasPermission(false);
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext("2d");
      // Draw the current frame of the video onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a Blob (image file)
      canvas.toBlob((blob) => {
        // Create a File from the Blob and append it to FormData
        const file = new File([blob], "captured-image.png", {
          type: "image/png",
        });
        const imageURL = canvas.toDataURL("image/png");
        //console.log(imageURL);
        //  const formData = new FormData();
        //  formData.append("image", file);
        NiceModal.show(Profile_Modal, {
          full_name,
          file: file,
          preview: imageURL,
        });
        modal.hide();
        stopCamera();
        // Send the FormData to the server (for example, using fetch)
      }, "image/png"); // You can also use 'image/jpeg' for JPEG format
    }
  };

  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          Camera
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row flex-wrap">
          <div className="w-100">
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              autoPlay
              style={{ background: "#333947" }}
            />

            <canvas
              ref={canvasRef}
              style={{ display: "none" }}
              width="640"
              height="480"
            />
          </div>
        </div>
        <div className="text-center">
          <Button
            onClick={captureImage}
            appearance="primary"
            style={{
              marginTop: 10,
              borderRadius: 20,
              background: "#F2641F",
              padding: "10px 25px",
            }}
          >
            Capture
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
});

function App() {
  // let cookies = new Cookies();
  // let validate_login = cookies.get("login_token");
  // let validate_users = cookies.get("token");
  // const network_details = cookies.get("network_details");

  // useEffect(() => {
  //   if (!validate_login && !validate_users) {
  //     logEvent(analytics, "new_user_count", {
  //       status: "new",
  //     });
  //     NiceModal.show(Phone_Modal);
  //   }
  //   logEvent(analytics, "visitor_count", {
  //     status: "existing",
  //     country: network_details?.country_name,
  //     city: network_details?.city,
  //   });
  // }, []);

  return (
    <NiceModal.Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} index />
          <Route path="/game/:id/:name" element={<Game />} />
          <Route path="/:name/:id/:name" element={<Game_stack />} />
          <Route path="/new_games" element={<Game_stack />} />
          <Route path="/recent_games" element={<Game_stack />} />
          <Route path="/updated" element={<Game_stack />} />
          <Route path="/reset" element={<Cookie_reset />} />
          <Route path="/terms_and_conditions" element={<Terms />} />
          <Route path="/privacy_policy" element={<Policy />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/playing_history" element={<History />} />
        </Routes>
      </BrowserRouter>
    </NiceModal.Provider>
  );
}

export default App;
