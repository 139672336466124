import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import { Input, InputGroup, MaskedInput } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { FlexboxGrid } from "rsuite";
import { Grid, Row, Col } from "rsuite";
import Service from "../service/apis";
import Logo from "../home_screen/skins/logo.png";
import HoverVideoPlayer from "react-hover-video-player";
import { Modal, Drawer, Button, HStack } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { alert, confirm, prompt } from "@rsuite/interactions";
import {
  Divider,
  Avatar,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  useToaster,
  AvatarGroup,
  Progress,
  DateRangePicker,
  Whisper,
  Tooltip,
} from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import moment from "moment";
import Cookies from "universal-cookie";
import { List } from "rsuite";
import PhoneInput from "react-phone-input-2";
import { IoCamera } from "react-icons/io5";
import ImageIcon from "@rsuite/icons/Image";
import axios from "axios";
import OtpInput from "react-otp-input";
import Avatar_data from "../avatars.json";
import { TiTick } from "react-icons/ti";
import { endOfWeek, startOfWeek } from "rsuite/esm/internals/utils/date";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Flag from "react-world-flags";
import useStore from "../service/zustand";
import Countdown from "react-countdown";
import Header from "../Header";
import CCalendar from "../CCalendar.js";
import CustomSelect from "../customSelect.js";
import "../CCalendar.css";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
// import "react-calendar/dist/Calendar.css";

let BASE_URL = process.env.REACT_APP_BASE_URL_V2;

const Leaderboard = () => {
  const navigate = useNavigate();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  let ACCESS_TOKEN_GUEST = cookies.get("token");
  const network_details = cookies.get("network_details");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [stackList, setStackList] = useState([]);
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredTitles, setFilteredTitles] = useState(data);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [user_id, setUser_id] = useState(0);
  const [leader_avatar, setLeader_avatar] = useState(0);

  const [username, setUsername] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [start_of_week, setStart_of_week] = useState("");
  const [end_of_week, setEnd_of_week] = useState("");
  const [calender_toggle, setCalender_toggle] = useState(false);
  const {
    profile_status_load,
    add_profile_load,
    token,
    start_week_store,
    end_week_store,
  } = useStore();

  const disabledDate = (date) => {
    const today = new Date();
    return date > today; // Disable future dates
  };

  const [start_week, setStart_week] = useState("");
  const [end_week, setEnd_week] = useState("");

  const handleDateSelect = (date) => {
    //alert(`Selected date: ${date.toDateString()}`);
    console.log(`Selected date: ${date}`);
    setTimeout(() => {
      setCalender_toggle(false);
    }, 1000);

    // leaderboard_fetch_search(
    //   moment(start_week_store.start_week_store).format("YYYY-MM-DD"),
    //   moment(end_week_store.end_week_store).format("YYYY-MM-DD")
    // );
  };
  // console.log(
  //   "called",
  //   start_week_store.start_week_store.toISOString().split("T")[0],
  //   end_week_store?.end_week_store.toISOString().split("T")[0]
  //   // end_week_store.end_week_store
  // );
  const tooltip = (
    <Tooltip>
      As a guest you are agreeing to the terms that you are 13+ years of age. To
      get the advantages and special privilages of partcipating in the Weekly
      Leaderboard Challenges, you need to Login.
    </Tooltip>
  );
  useEffect(() => {
    visitor_details();
  }, [profile_status_load]);

  const visitor_details = () => {
    Service.visitor_details()
      .then((res) => {
        let date = res?.data?.created_at;
        if (res.status == 1) {
          setFileInfo(res?.data?.profile_picture);
          setPercent(res?.data?.profile_completion_percentage);
          setAvatar_id(res?.data?.avatar_id);
          setUsername(res?.data?.full_name);
          setUser_id(res?.data?.id);
        }
      })
      .catch((e) => console.log(e));
  };

  const filteredAvatars = Avatar_data?.filter(
    (avatar) => avatar?.avatar_id === avatar_id
  );

  // console.log({ filteredAvatars });

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    leaderboard_fetch();
  }, []);

  function getStartEndOfWeekMondayUTC(date = new Date()) {
    // Get the current day of the week in UTC (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    // const dayOfWeek = date.getDay(); //date.getUTCDay();

    // // Calculate the difference in days to the start of the week (Monday) in UTC
    // const diffToMonday = dayOfWeek === 0 ? 7 : dayOfWeek; //0 ? -6 : 1 - dayOfWeek;

    // // Set the start of the week to the calculated Monday (in UTC)
    // const startOfWeek = new Date(date);
    // //startOfWeek.setUTCDate(date.getUTCDate() + diffToMonday);
    // startOfWeek.setDate(date.getDate() + diffToMonday);

    // // Set the end of the week to the following Sunday (in UTC)
    // const endOfWeek = new Date(startOfWeek);
    // //endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6); // Move to the following Sunday
    // endOfWeek.setDate(startOfWeek.getDate() + 6);

    const dayOfWeek = date.getDay();

    // Calculate the difference in days to the start of the week (Monday) in local time
    const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek - 1;

    // Set the start of the week to the calculated Monday (in local time)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() + diffToMonday);

    // Set the end of the week to the following Sunday (in local time)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the following Sunday

    return {
      startOfWeek: startOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      endOfWeek: endOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      startOfWeeks: startOfWeek, // Format as YYYY-MM-DD
      endOfWeeks: endOfWeek,
    };
  }
  const { startOfWeek, endOfWeek, startOfWeeks, endOfWeeks } =
    getStartEndOfWeekMondayUTC();

  const leaderboard_fetch = () => {
    const { startOfWeek, endOfWeek } = getStartEndOfWeekMondayUTC();
    let params = {
      startDate: startOfWeek,
      endDate: endOfWeek,
    };
    Service.leaderboard(params)
      .then((res) => {
        console.log(res?.data);
        setLeaderboard(res?.data);
      })
      .catch((e) => console.log(e));
  };

  // useEffect(() => {
  //   leaderboard_fetch_search(
  //     moment(start_week_store?.start_week_store).format("YYYY-MM-DD"),
  //     moment(end_week_store?.end_week_store).format("YYYY-MM-DD")
  //   );
  //   setStart_week(start_week_store?.start_week_store);
  //   setEnd_week(end_week_store?.end_week_store);
  // }, [start_week_store?.start_week_store, end_week_store?.end_week_store]);

  const leaderboard_fetch_search = (start, end) => {
    let params = {
      startDate: start,
      endDate: end,
    };
    Service.leaderboard(params)
      .then((res) => {
        // console.log(res?.data);
        setLeaderboard(res?.data);
      })
      .catch((e) => console.log(e));
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const handleInputChange = (event) => {
    // setSearch(event);
    //console.log(event.target);
    if (!event) {
      setVisible(false);
      // on_game_list();
    } else if (event) {
      setVisible(true);
    }
    let params = {
      query: event,
    };
    on_search(params);
  };
  const on_search = (params) => {
    Service.search(params)
      .then((res) => {
        // console.log(res.data);
        setSearch_data(res?.data);
      })
      .catch((e) => e.message);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.flag === "new_game") {
      new_added_game();
    } else if (location?.state?.flag === "recent_game") {
      recent_game();
    } else if (location?.state?.flag === "updated") {
      updated_list();
    } else {
      on_game_list();
    }
    category_techology_list();
  }, [location?.state]);

  const on_game_list = () => {
    let params;
    if (location?.state?.flag == "category") {
      params = {
        category_id: location?.state?.datas.category_id.toString(),
      };
    } else {
      params = {
        technology_id: location?.state?.datas.technology_id.toString(),
      };
    }
    Service.game_list(params)
      .then((res) => {
        // console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => e.message);
  };

  const updated_list = () => {
    Service.updated_games()
      .then((res) => {
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => e.message);
  };

  const new_added_game = () => {
    Service.new_added_games()
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const recent_game = () => {
    Service.recent_games()
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const category_techology_list = () => {
    Service.category_technology_list()
      .then((res) => {
        //console.log(res.data);
        setStackList(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const handleSearch = (e) => {
    setSearchValue(e);
    if (searchValue) {
      const filteredData = data.filter((item) => {
        return Object.values(item.title)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredTitles(filteredData);
    } else {
      setFilteredTitles(data);
    }
  };
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [activeIndex, setActiveIndex] = useState(null); // State to track active list item

  const handleClick = (index) => {
    setActiveIndex(index); // Set the clicked item's index as active
  };
  const [activeIndexTech, setActiveIndexTech] = useState(null); // State to track active list item

  const handleClickTech = (index) => {
    setActiveIndexTech(index); // Set the clicked item's index as active
  };

  // const LeaderAvatars = leaderboard.slice.map((a) => a.avatar_id);
  // console.log("Leader", LeaderAvatars);

  let ACCESS_GUEST_USER = cookies.get("token");
  const [value, onChange] = useState([new Date(), new Date()]);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [previousDate, setPreviousDate] = useState(null); // To track the previously selected date

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const generateCalendarGrid = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    const startOffset = firstDayOfMonth;
    const endOffset = 6 - lastDayOfMonth;

    const placeholdersBefore = Array.from({ length: startOffset }, () => null);
    const placeholdersAfter = Array.from({ length: endOffset }, () => null);

    return [...placeholdersBefore, ...daysInMonth, ...placeholdersAfter];
  };

  // Adjusted function to get the week range (Monday to Sunday)
  const getWeekRange = (date) => {
    const dayIndex = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    // Adjust dayIndex so that Monday is the first day (0) and Sunday is the last day (6)
    const adjustedDayIndex = dayIndex === 0 ? 7 : dayIndex;

    // Start of the week (Monday)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - adjustedDayIndex);
    startOfWeek.setHours(0, 0, 0, 0); // Reset time to start of the day (00:00:00)

    // End of the week (Sunday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday is 6 days after Monday
    endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day (23:59:59)

    return { startOfWeek, endOfWeek };
  };

  const isDateInWeek = (date, weekRange) => {
    return date >= weekRange.startOfWeek && date <= weekRange.endOfWeek;
  };

  const getCurrentWeekRange = () => {
    return getWeekRange(new Date()); // Using today's date to calculate the current week
  };

  const handleDateClick = (date) => {
    if (selectedDate) setPreviousDate(selectedDate);
    setSelectedDate(date);
    //if (date) onDateSelect(date);
    // Get the week range for the clicked date
    const { startOfWeek, endOfWeek } = getWeekRange(date);

    // Pass the start and end of week to the console
    leaderboard_fetch_search(
      moment(startOfWeek).format("YYYY-MM-DD"),
      moment(endOfWeek).format("YYYY-MM-DD")
    );
    setStart_week(startOfWeek);
    setEnd_week(endOfWeek);
    setCalender_toggle(false);
    console.log(
      "Start of Week singleton: ",
      moment(startOfWeek).format("YYYY-MM-DD")
    );
    console.log("End of Week singleton: ", endOfWeek.toLocaleDateString());
  };

  const calendarGrid = generateCalendarGrid(currentDate);
  const selectedWeekRange = selectedDate ? getWeekRange(selectedDate) : null;
  const previousWeekRange = previousDate ? getWeekRange(previousDate) : null;
  const currentWeekRange = getCurrentWeekRange();

  return (
    <div>
      <Header onPress={toggleMenu} />
      {/* <CustomSelect /> */}

      <div className="container-fluid pl-80 d-flex flex-column justify-content-between align-items-none h-100">
        <div className="row ">
          {/* NAVIGATION */}
          <div className={`navigation ${isActive ? "active" : ""}`}>
            <p
              className="m-2 ps-5 d-flex align-items-center d-md-none"
              style={{ color: "#f2641f" }}
            >
              {ACCESS_TOKEN_USER
                ? username?.length > 17
                  ? username?.substring(0, 17 - 1) + ""
                  : username
                : "Guest User"}

              {!ACCESS_TOKEN_USER && (
                <Whisper
                  placement="bottom"
                  controlId="control-id-click"
                  trigger="click"
                  speaker={tooltip}
                >
                  <img
                    className="ms-2"
                    src={require("../assets/info-icon.png")}
                    width={15}
                    alt=""
                  />
                </Whisper>
              )}
            </p>
            <ul>
              <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/home.png")}
                  />
                </span>
                <span className="title">Home</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/new_games", {
                    state: {
                      flag: "new_game",
                      name: "New Game",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/star.png")}
                  />
                </span>
                <span className="title">New Games</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/recent_games", {
                    state: {
                      flag: "recent_game",
                      name: "Recently Played",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/recent.png")}
                  />
                </span>
                <span className="title">Recently Played</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/updated", {
                    state: {
                      flag: "updated",
                      name: "Updated",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/updated.png")}
                  />
                </span>
                <span className="title">Updated</span>
              </li>
            </ul>

            <ul className="mt-5">
              {stackList?.categories?.map((item, index) => {
                //console.log(item.game_img);
                return (
                  <li
                    key={index}
                    className={activeIndex === index ? "active" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      logEvent(analytics, "games_most_categories_visited", {
                        category_id: item?.category_id_,
                        category_name: item?.category_name,
                      });
                      logEvent(analytics, "mostgames_categories_countries", {
                        category_name: item?.category_name,
                        country: network_details?.country_name,
                        city: network_details?.city,
                      });
                      navigate(
                        `/category/${item?.category_id}/${item?.category_name}`,
                        {
                          state: {
                            datas: item,
                            name: item?.category_name,
                            flag: "category",
                          },
                        }
                      );
                      handleClick(index);
                    }}
                  >
                    <span className="icon">
                      <img
                        key={index}
                        style={{ cursor: "pointer" }}
                        src={item?.icon}
                        className="color1"
                      />
                    </span>
                    <span className="title">{item.category_name}</span>
                    {/* <p style={{ color: "wheat" }}>{item.category_name}</p> */}
                  </li>
                );
              })}
            </ul>

            <ul className="mt-5">
              {stackList?.technologies?.map((item, index) => {
                //console.log(item.game_img);
                return (
                  <li
                    // key={index}
                    // className={activeIndex === index ? "active" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      logEvent(analytics, "games_most_technologies_visited", {
                        technology_id: item?.technology_id,
                        technology_name: item?.technology_name,
                      });
                      logEvent(analytics, "mostgames_technology_countries", {
                        technology_name: item?.technology_name,
                        country: network_details?.country_name,
                        city: network_details?.city,
                      });
                      navigate(
                        `/technology/${item?.technology_id}/${item?.technology_name}`,
                        {
                          state: {
                            datas: item,
                            name: item?.technology_name,
                            flag: "technology",
                          },
                        }
                      );
                      //handleClickTech(index);
                    }}
                  >
                    <span className="icon">
                      <img
                        key={index}
                        style={{ cursor: "pointer" }}
                        src={item?.icon}
                        className="color1"
                      />
                    </span>
                    <span className="title">{item.technology_name}</span>
                  </li>
                );
              })}
            </ul>

            <ul className="otherLink">
              <li
                onClick={() =>
                  navigate("/terms_and_conditions", {
                    state: {
                      flag: "t&c",
                      name: "Terms and Conditions",
                    },
                  })
                }
              >
                <span>Terms & Conditions</span>
              </li>
              <li
                onClick={() =>
                  navigate("/privacy_policy", {
                    state: {
                      flag: "policy",
                      name: "Privacy Policy",
                    },
                  })
                }
              >
                <span>Privacy</span>
              </li>
            </ul>
          </div>
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-3">
            <div className="row d-block d-lg-none">
              <div className="col-12 mb-3 d-flex justify-content-center">
                <InputGroup>
                  <Input
                    //onChange={handleSearch}
                    //value={searchValue}
                    // value={search}
                    onChange={handleInputChange}
                    className="search-box"
                    placeholder={"Search Game"}
                  />
                  <InputGroup.Addon style={{ top: "0px" }}>
                    {/* <SearchIcon className="icon_awesome_search1" color="#FFFFFF" /> */}
                    <img src={require("./skins/search.png")} alt="" />
                  </InputGroup.Addon>
                </InputGroup>
                {visible && (
                  <div className="custom-picker-popup-mobile">
                    {search_data?.map((item) => {
                      return (
                        <div
                          className="d-flex align-items-center searchItem"
                          onClick={() => {
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            });
                            //window.location.reload();
                          }}
                        >
                          <img
                            style={{
                              borderRadius: 5,
                              margin: 5,
                              objectFit: "cover",
                            }}
                            src={item.cover_picture}
                            height={"40px"}
                            width={"40px"}
                          />
                          <div className="ms-2">
                            {item.title}
                            <div className="d-flex gameInfo">
                              <small>{item?.category_names}</small>&nbsp; |
                              &nbsp;
                              <small>{item?.technology_names}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-4 col-lg-4 col-xl-3 mb-3 mb-lg-0">
                <div className="dark-bg h-100">
                  <h5 className="leaderboardTitle mb-3">Top Players</h5>
                  {/* <p className="leaderboardSubTitle">
                    {`${moment(start_week || startOfWeek).format(
                      "DD MMM YY"
                    )} - ${moment(end_week || endOfWeek).format("DD MMM YY")}`}
                  </p> */}
                  <div className="row m-0 mb-4">
                    <div className="col-12 mb-4 text-center">
                      <div className="d-flex flex-column align-items-center">
                        <div className="userPosition mb-3">
                          {leaderboard[0] ? (
                            <Avatar
                              // borderColor="#F6BA00"
                              size="xl"
                              // bordered

                              src={
                                leaderboard[0]?.profile_picture ||
                                (filteredAvatars.length > 0 &&
                                  require(`../assets/avatars/${filteredAvatars[0]?.avatar_icon}`))
                              }
                              circle
                            />
                          ) : null}
                          {leaderboard[0] && (
                            <div className="positionBadge">
                              <img
                                className="w-100"
                                src={require("../assets/avatars/gold.png")}
                                alt=""
                              />
                              <span className="positionNumber">1</span>
                            </div>
                          )}
                        </div>
                        <p className="playerName m-0 text-white">
                          {leaderboard[0] && (
                            <Flag
                              className="me-2"
                              height={"20px"}
                              width={"20px"}
                              code={leaderboard[0]?.country_code}
                            />
                          )}
                          {leaderboard[0]?.visitor_id == user_id
                            ? "Me"
                            : leaderboard[0]?.full_name?.length > 15
                            ? leaderboard[0]?.full_name?.substring(0, 15 - 1) +
                              ""
                            : leaderboard[0]?.full_name}
                        </p>
                        {leaderboard[0] && (
                          <p className="d-flex align-items-center points text-white">
                            <img
                              src={require("../assets/star.png")}
                              width={15}
                              alt=""
                              className="me-1"
                            />
                            {leaderboard[0]?.equivalent_point}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column align-items-center">
                        <div className="userPosition mb-3">
                          {leaderboard[1] ? (
                            <Avatar
                              size="lg"
                              // borderColor="#31A4FF"
                              // bordered
                              src={
                                leaderboard[1]?.profile_picture ||
                                (filteredAvatars?.length > 0 &&
                                  require(`../assets/avatars/${filteredAvatars[0]?.avatar_icon}`))
                              }
                              circle
                            />
                          ) : null}
                          {leaderboard[1] && (
                            <div className="positionBadge2">
                              <img
                                className="w-100"
                                src={require("../assets/avatars/silver.png")}
                                alt=""
                              />
                              <span className="positionNumber">2</span>
                            </div>
                          )}
                        </div>

                        <p className="playerName m-0 text-white text-nowrap">
                          {leaderboard[1] && (
                            <Flag
                              className="me-2"
                              height={"20px"}
                              width={"20px"}
                              code={leaderboard[0]?.country_code}
                            />
                          )}
                          {leaderboard[1]?.visitor_id == user_id
                            ? "Me"
                            : leaderboard[1]?.full_name?.length > 15
                            ? leaderboard[1]?.full_name?.substring(0, 15 - 1) +
                              ""
                            : leaderboard[1]?.full_name}
                        </p>
                        {leaderboard[1] && (
                          <p className="d-flex align-items-center points text-white">
                            <img
                              src={require("../assets/star.png")}
                              width={15}
                              alt=""
                              className="me-1"
                            />
                            {leaderboard[1]?.equivalent_point}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column align-items-center">
                        <div className="userPosition mb-3">
                          {leaderboard[2] ? (
                            <Avatar
                              size="lg"
                              // borderColor="#6A73A0"
                              // bordered
                              src={
                                leaderboard[2]?.profile_picture ||
                                (filteredAvatars?.length > 0 &&
                                  require(`../assets/avatars/${filteredAvatars[0]?.avatar_icon}`))
                              }
                              circle
                            />
                          ) : null}
                          {leaderboard[2] && (
                            <div className="positionBadge2">
                              <img
                                className="w-100"
                                src={require("../assets/avatars/bronze.png")}
                                alt=""
                              />
                              <span className="positionNumber">3</span>
                            </div>
                          )}
                        </div>
                        <p className="playerName m-0 text-white text-nowrap">
                          {leaderboard[2] && (
                            <Flag
                              className="me-2"
                              height={"20px"}
                              width={"20px"}
                              code={leaderboard[2]?.country_code}
                            />
                          )}
                          {leaderboard[2]?.visitor_id == user_id
                            ? "Me"
                            : leaderboard[2]?.full_name?.length > 15
                            ? leaderboard[2]?.full_name?.substring(0, 15 - 1) +
                              ""
                            : leaderboard[2]?.full_name}
                        </p>
                        {leaderboard[2] && (
                          <p className="d-flex align-items-center points text-white">
                            <img
                              src={require("../assets/star.png")}
                              width={15}
                              alt=""
                              className="me-1"
                            />
                            {leaderboard[2]?.equivalent_point}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8 col-lg-8 col-xl-9">
                <div className="dark-bg h-100 px-3">
                  <div className="row m-0">
                    <div className="col mb-2 d-flex align-items-center justify-content-between">
                      <h5 className="leaderboardTitle">Leaderboard</h5>
                      <span className="date">
                        {/* <DateRangePicker
                          onChange={onChange}
                          value={value}
                          className="text-white"
                        /> */}
                        {/* {calender_toggle && (
                          <CCalendar onDateSelect={handleDateSelect} />
                        )} */}

                        {calender_toggle && (
                          <div className="calendar">
                            <div className="calendar-header text-white">
                              <button
                                onClick={() =>
                                  setCurrentDate(
                                    new Date(
                                      currentDate.getFullYear(),
                                      currentDate.getMonth() - 1,
                                      1
                                    )
                                  )
                                }
                              >
                                <i class="fa-solid fa-chevron-left"></i>
                              </button>
                              <h2>
                                {currentDate.toLocaleDateString("en-US", {
                                  month: "short",
                                  year: "numeric",
                                })}
                              </h2>
                              <button
                                onClick={() =>
                                  setCurrentDate(
                                    new Date(
                                      currentDate.getFullYear(),
                                      currentDate.getMonth() + 1,
                                      1
                                    )
                                  )
                                }
                              >
                                <i class="fa-solid fa-chevron-right"></i>
                              </button>
                            </div>
                            <div className="calendar-grid">
                              {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map(
                                (day) => (
                                  <div
                                    key={day}
                                    className="calendar-day-header text-white"
                                  >
                                    {day}
                                  </div>
                                )
                              )}
                              {calendarGrid.map((day, index) => (
                                <div
                                  key={index}
                                  className={`calendar-day ${
                                    day &&
                                    selectedWeekRange &&
                                    isDateInWeek(day, selectedWeekRange) &&
                                    "highlighted-week-current"
                                  } ${
                                    day &&
                                    currentWeekRange &&
                                    isDateInWeek(day, currentWeekRange) &&
                                    "highlighted-week-previous"
                                  }`}
                                  onClick={() => day && handleDateClick(day)}
                                >
                                  <div
                                    key={index}
                                    className={`${
                                      day &&
                                      currentWeekRange &&
                                      isDateInWeek(day, currentWeekRange) &&
                                      "highlighted-week-previous"
                                    }`}
                                  >
                                    {day ? day.getDate() : ""}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setCalender_toggle(!calender_toggle)}
                          className="orange_sec"
                        >
                          {`${moment(start_week || startOfWeek).format(
                            "DD MMM"
                          )} - ${moment(end_week || endOfWeek).format(
                            "DD MMM, YYYY"
                          )}`}
                          <span
                            // className="editLink d-flex align-items-center"
                            style={{ pointerEvents: "none", cursor: "pointer" }}
                          >
                            <img
                              style={{ marginLeft: 6 }}
                              src={require("../assets/down-arrow.png")}
                              width={14}
                              alt=""
                            />
                          </span>
                        </span>
                        {/* <DateRangePicker
                          editable={false}
                          disabledDate={disabledDate}
                          style={{ background: "none" }}
                          defaultValue={[
                            new Date(startOfWeeks),
                            new Date(endOfWeeks),
                          ]}
                          oneTap
                          showOneCalendar
                          hoverRange="week"
                          isoWeek
                          placement="autoVerticalEnd"
                          onChange={(e) => {
                            leaderboard_fetch_search();
                            setStart_of_week(moment(e[0]).format("YYYY-MM-DD"));
                            setEnd_of_week(moment(e[1]).format("YYYY-MM-DD"));
                            setStart_week(moment(e[0]));
                            setEnd_week(moment(e[1]));
                          }}
                          ranges={[]}
                        /> */}
                      </span>
                    </div>
                    <div className="col-12">
                      <div className="scrollTable">
                        <table className="leaderboardTable w-100 text-white">
                          {leaderboard?.slice(3).map((item, index) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    width: "50px",
                                    background:
                                      item?.visitor_id == user_id && "#3D4063",
                                  }}
                                >
                                  {index + 4}
                                </td>
                                <td
                                  onClick={() => {
                                    if (item?.visitor_id == user_id) {
                                      navigate("/playing_history", {
                                        state: {
                                          start_weeks:
                                            start_week || startOfWeek,
                                          end_weeks: end_week || endOfWeek,
                                        },
                                      });
                                    }
                                  }}
                                  style={{
                                    width: "400px",
                                    background:
                                      item?.visitor_id == user_id && "#3D4063",
                                    cursor:
                                      item?.visitor_id == user_id && "pointer",
                                  }}
                                >
                                  <div className=" d-flex align-items-center">
                                    <Avatar
                                      src={
                                        item.profile_picture ||
                                        (filteredAvatars.length > 0 &&
                                          require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
                                      }
                                      className="me-3"
                                      circle
                                    />
                                    {item?.visitor_id == user_id
                                      ? "Me"
                                      : item?.full_name}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    if (item?.visitor_id == user_id) {
                                      navigate("/playing_history", {
                                        state: {
                                          start_weeks:
                                            start_week || startOfWeek,
                                          end_weeks: end_week || endOfWeek,
                                        },
                                      });
                                    }
                                  }}
                                  style={{
                                    background:
                                      item?.visitor_id == user_id && "#3D4063",
                                    cursor:
                                      item?.visitor_id == user_id && "pointer",
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <Flag
                                      className="me-2"
                                      height={"20px"}
                                      width={"20px"}
                                      code={"ind"}
                                    />
                                    {item?.country}
                                  </div>
                                </td>
                                <td
                                  onClick={() => {
                                    if (item?.visitor_id == user_id) {
                                      navigate("/playing_history", {
                                        state: {
                                          start_weeks:
                                            start_week || startOfWeek,
                                          end_weeks: end_week || endOfWeek,
                                        },
                                      });
                                    }
                                  }}
                                  style={{
                                    width: "100px",
                                    background:
                                      item?.visitor_id == user_id && "#3D4063",
                                    cursor:
                                      item?.visitor_id == user_id && "pointer",
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-end">
                                    <img
                                      src={require("../assets/star.png")}
                                      width={15}
                                      alt=""
                                      className="me-1"
                                    />
                                    {item?.equivalent_point}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                      {leaderboard == 0 && (
                        <div className="row">
                          <div className="col">
                            <h5 className="text-center text-white">
                              Sorry! No leaderboard list available for selected
                              period.
                            </h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                {/* <p className="d-block d-lg-flex align-items-center justify-content-center">
                  Development Partner
                  <img src={require("./skins/rat.png")} alt="" />
                  <a href="https://redappletech.com/" target="_blank">
                    <span>Red Apple Technology </span>
                  </a>
                  &nbsp; | Learning Partner
                  <img src={require("./skins/ral.png")} alt="" />
                  <a href="https://redapplelearning.in/" target="_blank">
                    <span>Red Apple Learning</span>
                  </a>
                </p> */}
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112">
          {/* {searchValue.length > 1
            ? filteredTitles?.map((item, index) => {
                //console.log(item);
                return (
                  <div>
                    <img
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/game/${item?.id}/${item?.title}`, {
                          state: {
                            datas: item,
                          },
                        })
                      }
                      src={item?.cover_picture}
                      className="connect_the_ball_ek11"
                    />
                  </div>
                );
              })
            : data?.map((item, index) => {
                //console.log(item);
                return (
                  <div>
                    <img
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/game/${item?.id}/${item?.title}`, {
                          state: {
                            datas: item,
                          },
                        })
                      }
                      src={item?.cover_picture}
                      className="connect_the_ball_ek11"
                    />
                  </div>
                );
              })} */}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
