import React, { useState, useEffect, useRef } from "react";
// import "./Game.css";
import HoverVideoPlayer from "react-hover-video-player";
import { useLocation, useNavigate } from "react-router-dom";
import Iframe from "react-iframe";
import moment from "moment";
import Service from "../service/apis";
import { Input, InputGroup, MaskedInput } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import Logo from "../assets/Playzhub.png";
import star from "../assets/star.png";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { Scrollbar, Navigation } from "swiper/modules";
import { InputPicker, Avatar } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { Modal, Drawer, Button, HStack, AvatarGroup, Progress } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { alert, confirm, prompt } from "@rsuite/interactions";
import {
  Divider,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  useToaster,
  Tooltip,
  Whisper,
} from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import PhoneInput from "react-phone-input-2";
import { IoCamera } from "react-icons/io5";
import ImageIcon from "@rsuite/icons/Image";
import axios from "axios";
import OtpInput from "react-otp-input";
import Avatar_data from "../avatars.json";
import { TiTick } from "react-icons/ti";
import { FaCommentsDollar, FaHeart } from "react-icons/fa";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Flag from "react-world-flags";
import useStore from "../service/zustand";
import Countdown from "react-countdown";
import Header from "../Header";
import Nostr from "../assets/nostr.png";
import Custom_ads from "../Custom_ads";
import calculateAspectRatio from "calculate-aspect-ratio";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

let BASE_URL = process.env.REACT_APP_BASE_URL_V2;

const Profile_Modal = NiceModal.create(
  ({ full_name, file, preview, select_dob }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);
    const [fileList, setFileList] = useState(null);
    const [err, setErr] = useState(false);
    const [name, setName] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);
    const [name_status, setName_status] = useState(true);

    const tooltip = (
      <Tooltip>
        If DOB is not declared then you are agreeing to the terms that you are
        13+ years of age. To get the advantages and special privilages of
        partcipating in the Weekly Leaderboard Challenges, you need to Login.
      </Tooltip>
    );
    // Function to handle avatar selection
    const handleAvatarClick = (index) => {
      console.log({ index });
      setSelectedAvatar(index);
    };
    function previewFile(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const onChange = (date, dateString) => {
      console.log(date, dateString);
    };
    const onContactFromSubmit = async () => {
      setLoading(true);
      if (!name) {
        setErr(true);
        setLoading(false);
        return;
      }
      setErr(false);
      let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
      let form = new FormData();
      form.append("full_name", name);
      form.append("profile_picture", file || fileList);
      form.append("date_of_birth", select_dob || "");
      form.append("gender", selectedGender || "");
      form.append("avatar_id", selectedAvatar || "");
      try {
        await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status == 0) {
              setLoading(false);
            }
            if (data.status == 1) {
              setLoading(false);
              modal.hide();

              window.location.reload(true);
            }
          })
          .catch((e) => e.message);
      } catch (e) {
        throw e;
      }
    };

    const handleUsername = (event) => {
      setName(event);
    };

    useEffect(() => {
      setName(full_name);
    }, []);

    const genders = [
      "Male",
      "Female",
      "Non-Binary",
      "Other",
      "Prefer not to say",
    ];

    // State to track the selected gender
    const [selectedGender, setSelectedGender] = useState("");

    // Handle change in selected gender
    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1960; year <= currentYear; year++) {
      years.push(year);
    }
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
    const handleYearChange = (event) => {
      console.log(event.target.value);
      setSelectedYear(event.target.value);
    };

    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");
    const [headerText, setHeaderText] = useState("Select Gender"); // Header text

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (gender) => {
      setSelectedGender(gender);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <h4 className="mb-3 fw-normal">Update Profile </h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              This information is shared exclusively with
              <br />
              Thinktrek and will not be visible on your profile.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-row flex-wrap  pt-1">
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "10px",
                padding: 0,
              }}
            >
              {!preview ? (
                <IoCamera
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      full_name,

                      file,
                      preview,
                    });
                    modal.hide();
                  }}
                  style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
                />
              ) : (
                <img
                  style={{ borderRadius: 35, width: 40, height: 40 }}
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      full_name,
                      file,
                      preview,
                    });
                    modal.hide();
                  }}
                  src={preview}
                  width="100%"
                  height="100%"
                />
              )}
            </button>
            <div className="position-relative d-flex">
              <Uploader
                onChange={(event) => setFileList(event[0].blobFile)}
                fileListVisible={false}
                listType="picture"
                action="/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value) => {
                    setFileInfo(value);
                    //console.log(value);
                  });
                }}
              >
                <button
                  style={{
                    background: "#F2641F",
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    border: "none",
                    margin: "0 5px",
                    marginBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  {fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : (
                    <ImageIcon style={{ fontSize: 15, color: "#fff" }} />
                  )}
                </button>
              </Uploader>
              <span className="fromGallery">
                <ImageIcon style={{ fontSize: 10, color: "#fff" }} />
              </span>
            </div>
            {Avatar_data.map((item, key) => {
              return (
                <AvatarGroup
                  spacing={6}
                  key={key}
                  className="position-relative"
                >
                  <Avatar
                    color="green"
                    bordered={selectedAvatar === item.avatar_id ? true : false}
                    key={key}
                    onClick={() => {
                      handleAvatarClick(item.avatar_id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 5px",
                      marginBottom: "10px",
                    }}
                    circle
                    src={require(`../assets/avatars/${item.avatar_icon}`)}
                    alt=""
                  />
                  {selectedAvatar === item.avatar_id && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        bottom: "11px",
                        right: "0",
                        width: "13px",
                        height: "13px",
                        borderRadius: "3px",
                        background: "#2ED573",
                        position: "absolute",
                      }}
                    >
                      <TiTick
                        style={{ fontSize: "10px" }}
                        className="text-white"
                      />
                    </div>
                  )}
                </AvatarGroup>
              );
            })}
          </div>
          <div className="editField" style={{ marginTop: 10 }}>
            <label className="text-white">Username*</label>
            <Input
              disabled={name_status ? true : false}
              value={name}
              placeholder="Fill username"
              onChange={handleUsername}
              style={{ background: "none" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Enter username
              </span>
            )}
            <span
              className="editLink d-flex align-items-center"
              onClick={() => setName_status(false)}
            >
              Edit
            </span>
          </div>
          <div className="editField" style={{ marginTop: 10 }}>
            <label className="text-white d-block">
              Date of birth
              <Whisper
                placement="top"
                controlId="control-id-click"
                trigger="click"
                speaker={tooltip}
              >
                <img
                  className="ms-2"
                  src={require("../assets/info-icon.png")}
                  width={15}
                  alt=""
                />
              </Whisper>
            </label>
            {/* <DatePicker
            placeholder={"Not specified"}
            disabled={dob_status ? true : false}
            oneTap
            placement="top"
            className="w-100"
            onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
          /> */}
            <Input
              disabled={true}
              value={select_dob}
              placeholder="Not Specified"
              style={{ background: "none" }}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Dob_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob,
                });
                modal.hide();
              }}
            >
              Edit
            </span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 10 }}
            onClick={toggleDropdown}
          >
            <label className="d-block">Gender</label>
            <input
              readOnly
              type="text"
              placeholder="not specified"
              value={selectedGender}
            />

            <div className="custom-select-container">
              {isOpen && (
                <>
                  <div className="custom-select-header">{headerText}</div>
                  <ul className="custom-select-dropdown">
                    <li onClick={() => handleSelect("Male")}>Male</li>
                    <li onClick={() => handleSelect("Female")}>Female</li>
                    <li onClick={() => handleSelect("Others")}>Others</li>
                    <li onClick={() => handleSelect("Non-Binary")}>
                      Non-Binary
                    </li>
                    <li onClick={() => handleSelect("Prefer not to say")}>
                      Prefer not to say
                    </li>
                  </ul>
                </>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="text-center">
            {!file &&
            !select_dob &&
            !selectedAvatar &&
            !selectedDate &&
            !selectedMonth &&
            !selectedYear &&
            !selectedGender &&
            !fileList ? (
              <Button
                onClick={() => {
                  onContactFromSubmit();
                  // modal.hide();
                }}
                className="guest-btn w-auto"
                style={{ borderRadius: 20, padding: "10px 25px" }}
                block
              >
                Skip for Now
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={onContactFromSubmit}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
                block
              >
                Save & Continue
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Phone_Modal = NiceModal.create(({ id, name }) => {
  const modal = useModal();
  let cookies = new Cookies();
  const [phone, setPhone] = useState("");
  const [dial, setDial] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  let validate_users = cookies.get("token");
  let IP_URL = process.env.REACT_APP_IP_URL;
  const { profile_status_load, add_profile_load, add_guest_token } = useStore();
  let [offline_data, setOffline_data] = useState({
    ip: "122.176.24.171",
    network: "122.176.24.0/24",
    version: "IPv4",
    city: "Kolkata",
    region: "West Bengal",
    region_code: "WB",
    country: "IN",
    country_name: "India",
    country_code: "IN",
    country_code_iso3: "IND",
    country_capital: "New Delhi",
    country_tld: ".in",
    continent_code: "AS",
    in_eu: false,
    postal: "700054",
    latitude: 22.518,
    longitude: 88.3832,
    timezone: "Asia/Kolkata",
    utc_offset: "+0530",
    country_calling_code: "+91",
    currency: "INR",
    currency_name: "Rupee",
    languages:
      "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
    country_area: 3287590,
    country_population: 1352617328,
    asn: "AS24560",
    org: "Bharti Airtel Ltd., Telemedia Services",
  });

  const guest_user = () => {
    if (!validate_users) {
      fetch_user_details();
    }
    ///window.location.reload(true);
  };
  const fetch_user_details = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        console.log({ res });
        cookies.set("network_details", res.data, { path: "/" });
        let data = res.data;
        validate_user(data);
      })
      .catch((e) => {
        console.log(e.config.data);
        if (e.config.data === undefined) {
          validate_user(offline_data);
        }
      });
  };

  const validate_user = (data) => {
    let params = {
      ip_address: data.ip,
      country: data.country_name,
      city: data.city,
      latitude: data.latitude,
      longitude: data.longitude,
      additional_details: JSON.stringify(data),
    };
    Service.login_register_visitor(params)
      .then((res) => {
        // console.log(res);
        logEvent(analytics, "guest_user_count", {
          country: data?.country_name,
          city: data?.city,
        });
        cookies.set("token", res.token, { path: "/" });
        add_guest_token(res.token);
        // window.location.reload(true);
      })
      .catch((e) => console.log(e.message));
  };
  const fetch_user_network = () => {
    axios
      .get(IP_URL)
      .then((res) => {
        cookies.set("network_details", res.data, { path: "/" });
      })
      .catch((e) => {
        // console.log(e.config.data)
        if (e.config.data === undefined) {
          // validate_user(offline_data)
        }
      });
  };
  const handleInputChange = (value, data) => {
    let code = `+${data.dialCode}`;
    setDial(code);
    let number = value.toString().slice(data.dialCode.length);
    setPhone(number);
  };
  let network = cookies.get("network_details");

  const onContinue = async (e) => {
    // e.preventDefault();
    setLoading(true);
    let params = {
      phone_number: phone,
    };
    await Service.login_number(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 0) {
          setLoading(false);
        }
        if (res.status == 1) {
          if (!network) {
            fetch_user_network();
          }
          setLoading(false);
          modal.hide();
          NiceModal.show(Otp_Modal, {
            number: phone,
            otp_code: res?.otp,
            dial_code: dial,
            expire: res?.otp_expires,
          });
        }
      })
      .catch((e) => console.log(e.message));
  };

  const handleKeyDown = (e) => {
    console.log(e);
    if (e.key === "Enter") {
      onContinue(e);
    }
  };
  return (
    <Modal
      className="rs-modal-xs"
      open={modal.visible}
      onClose={modal.hide}
      onExited={modal.remove}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="text-white" style={{ textAlign: "center" }}>
          <h4 className="mb-4 fw-normal">Log In</h4>
          <p className="mb-0" style={{ fontSize: "16px" }}>
            Welcome to the game portal
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <PhoneInput
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onContinue();
            }
          }}
          className="phone"
          copyNumbersOnly={true}
          //autoFormat={false}
          prefix="+"
          countryCodeEditable={false}
          placeholder="Fill mobile number"
          country={"in"}
          //  value={phone}
          onChange={(value, data, event, formattedValue) =>
            handleInputChange(value, data)
          }
        />
        <Button
          // onKeyDown={handleKeyDown}
          className="my-4"
          loading={loading}
          onClick={() => {
            onContinue();
          }}
          disabled={phone.length > 8 ? false : true}
          appearance="primary"
          style={{
            width: "40%",
            marginTop: 10,
            borderRadius: 20,
            background: phone.length > 8 ? "#F2641F" : "#818A97",
            padding: "10px 22px",
          }}
          block
        >
          Play Now
        </Button>
        <p
          className="text-secondary"
          style={{ justifyItems: "flex-end", flex: 1 }}
        >
          OR
        </p>
        <div style={{ marginTop: 10 }}></div>
        <Button
          className="guest-btn"
          onClick={() => {
            guest_user();
            modal.hide();
            // window.location.reload(true);
          }}
          // appearance="ghost"
          style={{ marginTop: 10, borderRadius: 20, width: "auto" }}
          block
        >
          Continue as Guest
        </Button>
        <hr />
        <p
          className="text-white text-center m-0 login_para"
          style={{ fontSize: "12px" }}
        >
          Note: As a guest you are agreeing to the terms that you are 13+ years
          of age. To get the advantages and special privilages of partcipating
          in the Weekly Leaderboard Challenges, you need to Login.
        </p>
      </Modal.Body>
    </Modal>
  );
});

const Otp_Modal = NiceModal.create(
  ({ number, otp_code, dial_code, expire }) => {
    const modal = useModal();
    let cookies = new Cookies();
    const [phone, setPhone] = useState("");
    const [dial, setDial] = useState("");
    const [show, setShow] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [expire_time, setExpire_time] = useState("");
    let [msg, setMsg] = useState("");
    const { profile_status_load, add_profile_load } = useStore();
    let network = cookies.get("network_details");

    const verifyCode = async () => {
      setLoading(true);
      let params = {
        phone_number: number,
        otp: otp,
        ipAddress: network?.ip,
        country: network?.country_name,
        country_code: dial_code,
        city: network?.city,
        latitude: network?.latitude,
        longitude: network?.longitude,
      };
      await Service.verify_otp(params)
        .then((res) => {
          //console.log(res.data.visitorDetails.full_name)
          if (res.status == 0) {
            setMsg(res?.message);
            setLoading(false);
          }
          if (res.status == 1) {
            // if (!res?.data?.visitorDetails?.full_name) {
            if (res?.data?.profile_completion_status == false) {
              logEvent(analytics, "registered_user_count", {
                country: network?.country_name,
                city: network?.city,
              });
              cookies.set("login_token", res.token, { path: "/" });
              modal.hide();
              NiceModal.show(Profile_Modal, {
                full_name: res?.data?.visitorDetails?.full_name,
              });
              setLoading(false);
            }
            add_profile_load(true);
            cookies.set("login_token", res.token, { path: "/" });
            modal.hide();
            setMsg("");
            ///window.location.reload();
            //NiceModal.show(Profile_Modal, { id: 'game_id', })
            setLoading(false);
          }
        })
        .catch((e) => console.log(e.message));
    };

    const onContinue = async () => {
      // setLoading(true);
      let params = {
        phone_number: number,
      };
      await Service.login_number(params)
        .then((res) => {
          //console.log(res);
          if (res.status == 0) {
            //setLoading(false);
          }
          if (res.status == 1) {
            setCode(res?.otp);
            setExpire_time(res?.otp_expires);
          }
        })
        .catch((e) => console.log(e.message));
    };

    const Resend = () => (
      <div
        className="text-end text-capitalize"
        style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={onContinue}
          className="text-white"
        >
          resend
        </span>
      </div>
    );

    // Renderer callback with condition
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <Resend />;
      } else {
        // Render a countdown
        return (
          <div
            className="text-start"
            style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
          >
            <span className="text-white">
              Expire in :{" "}
              <span style={{ color: "#F2641F" }}>
                {minutes}:{seconds}
              </span>
            </span>
          </div>
        );
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        console.log("entr");
        verifyCode();
      }
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <h4 className="mb-4 fw-normal">Verify</h4>
            <p
              className="mb-0"
              style={{ fontSize: "16px", whiteSpace: "normal" }}
            >
              {`Please enter your verification code sent to ${dial_code}${number}`}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p style={{ color: "red" }}>{msg}</p>
          <OtpInput
            inputType="tel"
            value={otp}
            onChange={setOtp}
            numInputs={5}
            containerStyle={{
              margin: "5px",
              justifyContent: "center",
            }}
            inputStyle={{
              height: "40px",
              width: "40px",
              margin: "5px",
              background: "#333947",
              color: "#F5F9FF",
              borderRadius: 5,
              border: "none",
            }}
            // renderSeparator={<span>-</span>}
            renderInput={(props) => (
              <input
                autoComplete="one-time-code"
                {...props}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            )}
          />

          <Countdown
            autoStart={true}
            date={moment(expire_time || expire)}
            renderer={renderer}
          />
          <p className="text-white m-0">{code || otp_code}</p>
          <Button
            disabled={otp.length == 5 ? false : true}
            className="text-white"
            loading={loading}
            onClick={() => {
              verifyCode();
              // modal.hide()
              // NiceModal.show(Profile_Modal)
            }}
            //#818A97
            // appearance="primary"
            style={{
              marginTop: 25,
              borderRadius: 20,
              padding: "10px 40px",
              background: otp.length == 5 ? "#F2641F" : "#818A97",
              color: otp.length == 5 ? "#fff" : "#ffffff47",
            }}
          >
            Verify
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
);

const Profile_edit_Modal = NiceModal.create(({ navigate, select_dob }) => {
  const modal = useModal();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  let network_details = cookies.get("network_details");
  const toaster = useToaster();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [fileList, setFileList] = useState(null);
  const [err, setErr] = useState(false);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [country_code, setCountry_code] = useState("");
  const [country_alias, setCountry_alias] = useState("");
  const [score, setScore] = useState("");
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [date_of_birth, setDate_of_birth] = useState("");
  const [fav_list, setFav_list] = useState([]);
  const [play_history, setPlay_history] = useState([]);
  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  useEffect(() => {
    visitor_details();
    fav_games();
  }, []);

  const log_out = () => {
    cookies.remove("token", { path: "/" });
    cookies.remove("network_details", { path: "/" });
    cookies.remove("login_token", { path: "/" });
    navigate("/");
    window.location.reload(true);
  };
  const tooltip = (
    <Tooltip>
      This is a help <i>tooltip</i> .
    </Tooltip>
  );
  const visitor_details = () => {
    Service.visitor_details()
      .then((res) => {
        let date = res?.data?.created_at;
        let date_of_birth = res?.data?.date_of_birth;

        if (res.status == 1) {
          setName(res?.data?.full_name);
          setDob(date);
          setDate_of_birth(date_of_birth);
          setGender(res?.data?.gender);
          setCountry(res?.data?.country);
          setScore(res?.data?.equivalent_point);
          setFileInfo(res?.data?.profile_picture);
          setPercent(res?.data?.profile_completion_percentage);
          setAvatar_id(res?.data?.avatar_id);
          setPlay_history(res?.data?.playing_history);
          setPhone(res?.data?.phone_number);
          setCountry_code(res?.data?.country_code);
          setCountry_alias(res?.data?.country_code_alias);
        }
      })
      .catch((e) => console.log(e));
  };

  const onContactFromSubmit = async () => {
    setLoading(true);
    if (!name) {
      setErr(true);
      setLoading(false);
      return;
    }
    setErr(false);
    let form = new FormData();
    form.append("full_name", name);
    form.append("profile_picture", fileList);
    form.append("date_of_birth", moment(dob).format("YYYY-MM-DD"));
    form.append("gender", gender);
    try {
      await fetch(
        `${process.env.REACT_APP_BASE_URL_V2}/api/edit-visitor-profile`,
        {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status == 0) {
            setLoading(false);
          }
          if (data.status == 1) {
            setLoading(false);
            modal.hide();
          }
        })
        .catch((e) => e.message);
    } catch (e) {
      throw e;
    }
  };

  const fav_games = () => {
    Service.fav_games()
      .then((res) => {
        setFav_list(res?.data);
      })
      .catch((e) => console.log);
  };

  const fav_status = (id, status) => {
    let params = {
      game_id: id,
      status: status,
    };
    Service.fav_games_status(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 1) {
          fav_games();
        }
      })
      .catch((e) => console.log(e));
  };

  const targetDate = new Date(dob);
  const currentDate = new Date();
  const timeDifference = currentDate - targetDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const filteredAvatars = Avatar_data?.filter(
    (avatar) => avatar?.avatar_id === avatar_id
  );

  return (
    <>
      <Modal
        onBackdropClick={() => modal.hide()}
        // dialogStyle={{
        //   width: "100%",
        //   // marginLeft: "-50%",
        //   height: "100%",
        //   marginTop: "5%",
        // }}
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
        className="profileModal"
      >
        {/* <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            Profile{" "}
          </Modal.Title>
        </Modal.Header> */}

        <Modal.Body className="p-0 mh-100">
          <div className="row gx-2">
            <div className="col-12 col-sm-3  mb-3 mb-lg-0">
              <div className="dark-bg h-100">
                <Modal.Title className="text-white text-center mb-4">
                  Profile{" "}
                </Modal.Title>
                <div className="text-center ">
                  {
                    <div className="position-relative profileAvatar">
                      <Avatar
                        alt=""
                        size="xl"
                        circle
                        src={
                          fileInfo ||
                          (filteredAvatars.length > 0 &&
                            require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
                        }
                      />
                      <div className="editIcon d-flex">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            modal.hide();
                            NiceModal.show(Update_Profile_Modal, {
                              avatar_id: avatar_id,
                              gender_update: gender,
                              date_of_birth: date_of_birth,
                              full_name: name,
                              profile_picture: fileInfo,
                            });
                          }}
                          src={require("../assets/edit.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  }
                </div>

                <div className="profileProgress mt-2">
                  <p
                    style={{ fontSize: "12px" }}
                    className="text-white m-0 d-flex justify-content-between align-items-center"
                  >
                    {`Your Profile is ${percent}% complete`}
                    <span
                      onClick={() => {
                        // if (percent !== 100) {
                        modal.hide();
                        NiceModal.show(Update_Profile_Modal, {
                          avatar_id: avatar_id,
                          gender_update: gender,
                          date_of_birth: date_of_birth,
                          full_name: name,
                          profile_picture: fileInfo,
                          select_dob,
                        });
                        //}
                      }}
                      style={{
                        color: "#F2641F",
                        cursor: "pointer",
                      }}
                    >
                      {percent == 100 ? `edit profile` : `complete now`}
                      <img
                        className="ms-1"
                        src={require("../assets/right-arrow.png")}
                        style={{ width: "5px" }}
                        alt=""
                      />
                    </span>
                  </p>
                  <Progress.Line
                    className="text-white p-0 mt-2"
                    percent={percent}
                    status={percent == 100 && "success"}
                    strokeColor={percent == 100 ? "green" : "#F2641F"}
                    strokeWidth={5}
                    trailColor={"#212530"}
                    showInfo={false}
                  />
                </div>

                <div className="profileDetails">
                  <div>
                    <p className="detailTitle">Your Score</p>
                    <p className="text-white d-flex align-items-center">
                      <img
                        className="me-1"
                        src={require("../assets/star.png")}
                        style={{ width: "15px" }}
                        alt=""
                      />
                      {score}
                    </p>
                  </div>
                  <div>
                    <p className="detailTitle">User Name</p>
                    <p className="text-white">{name}</p>
                  </div>
                  <div>
                    <p className="detailTitle">Country</p>
                    <p className="text-white">
                      {country}
                      <Flag
                        height={"20px"}
                        width={"20px"}
                        className="ms-2"
                        code={country_alias}
                      />
                    </p>
                  </div>
                  <div>
                    <p className="detailTitle">Phone Number</p>
                    <p className="text-white">{`${country_code} ${phone}`}</p>
                  </div>
                  <div>
                    <p className="detailTitle">Member Since</p>
                    <p className="text-white">
                      {daysDifference == 0 ? "Today" : daysDifference}{" "}
                      <span>
                        {daysDifference == 0
                          ? ""
                          : daysDifference > 1
                          ? "Days"
                          : "Day"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    onClick={() => {
                      navigate("/playing_history", {
                        state: {
                          history: play_history,
                        },
                      });
                      modal.hide();
                    }}
                    style={{
                      borderRadius: 20,
                      background: "#F2641F",
                      padding: "10px 25px",
                      color: "#fff",
                    }}
                    className="me-3"
                  >
                    Game History
                  </Button>
                  <Button
                    onClick={log_out}
                    appearance="primary"
                    style={{
                      borderRadius: 20,
                    }}
                    className="guest-btn "
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div className="dark-bg h-100 p-3">
                <Modal.Title className="text-white">
                  My Favourite Games
                </Modal.Title>
                <div className="row gx-2 mt-3 scrollBox">
                  {fav_list.map((item, index) => {
                    return (
                      <>
                        <div className="col-6 col-md-3 position-relative">
                          <div className="gameItem">
                            <HoverVideoPlayer
                              onClick={() => {
                                modal.hide();
                                navigate(`/game/${item?.id}/${item?.title}`, {
                                  state: {
                                    datas: item,
                                    flag: "home",
                                  },
                                });
                              }}
                              style={{ cursor: "pointer" }}
                              className="connect_the_ball_ek11 gameThumb"
                              restartOnPaused
                              overlayTransitionDuration={250}
                              videoSrc={item?.video_url || item?.video_upload}
                              pausedOverlay={
                                <img
                                  src={item?.cover_picture}
                                  alt=""
                                  className="connect_the_ball_ek11 gameThumb"
                                />
                              }
                              loadingOverlay={
                                <div className="loading-overlay">
                                  <div className="loading-spinner" />
                                </div>
                              }
                            />

                            <div className="hoverText">{item?.title}</div>
                            <div className="deleteGame">
                              <img
                                onClick={async () => {
                                  const options = {
                                    okButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                  };
                                  const result = await confirm(
                                    "Are you sure? You want to remove this game from your favourite games list",
                                    options
                                  );

                                  if (result) {
                                    fav_status(item?.id, 0);
                                  }
                                }}
                                src={require("../assets/close.png")}
                                style={{ width: 15 }}
                                alt=""
                              />
                            </div>
                          </div>
                          {item.tag == "new" && (
                            <div className="new-badge">New</div>
                          )}
                          {item.last_updated && (
                            <div className="update-badge">Updated</div>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                {fav_list.length == 0 && (
                  <div className="row">
                    <div className="col">
                      <h5 className="text-center text-white">
                        Sorry! Thre is no games in your Favourites List. Start
                        playing.
                      </h5>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

const Update_Profile_Modal = NiceModal.create(
  ({
    avatar_id,
    full_name,
    gender_update,
    date_of_birth,
    profile_picture,
    file,
    preview,
    select_dob,
  }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const toaster = useToaster();
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState(profile_picture);
    const [fileList, setFileList] = useState(null);
    const [err, setErr] = useState(false);
    const [name, setName] = useState(full_name);
    const [gender, setGender] = useState(gender_update);
    const [dob, setDob] = useState(date_of_birth);
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(avatar_id);
    const [dob_status, setDob_status] = useState(true);
    const [name_status, setName_status] = useState(true);
    //const previewUrl = URL.createObjectURL(file);
    // console.log(previewUrl);
    // Function to handle avatar selection
    const tooltip = (
      <Tooltip>
        If DOB is not declared then you are agreeing to the terms that you are
        13+ years of age. To get the advantages and special privilages of
        partcipating in the Weekly Leaderboard Challenges, you need to Login.
      </Tooltip>
    );
    const handleAvatarClick = (index) => {
      console.log({ index });
      setSelectedAvatar(index);
    };
    function previewFile(file, callback) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const onChange = (date, dateString) => {
      console.log(date, dateString);
    };
    const onContactFromSubmit = async () => {
      setLoading(true);
      if (!name) {
        setErr(true);
        setLoading(false);
        return;
      }
      setErr(false);
      let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
      let form = new FormData();
      form.append("full_name", name);
      form.append("profile_picture", file || fileList);
      form.append(
        "date_of_birth",
        //moment(dob).format("YYYY-MM-DD")
        select_dob || ""
      );
      form.append("gender", selectedGender || "");
      form.append("avatar_id", selectedAvatar || "");
      try {
        await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
          method: "POST",
          body: form,
          headers: {
            //  'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log("Success thread Contact===========>:", data);
            if (data.status == 0) {
              setLoading(false);
            }
            if (data.status == 1) {
              setLoading(false);
              //    console.log(
              //   "Success thread Contact===========>:",
              //   data
              // );
              modal.hide();
              window.location.reload();
            }
          })
          .catch((e) => e.message);
      } catch (e) {
        throw e;
      }
    };

    const handleUsername = (event) => {
      setName(event);
    };

    const generateRandomUsername = () => {
      const adjectives = [
        "Swift",
        "Mighty",
        "Epic",
        "Mystic",
        "Viking",
        "Fearless",
        "Shadow",
        "Blazing",
      ];
      const nouns = [
        "Warrior",
        "Knight",
        "Dragon",
        "Hunter",
        "Champion",
        "Ninja",
        "Phoenix",
        "Titan",
      ];
      const randomAdjective =
        adjectives[Math.floor(Math.random() * adjectives.length)];
      const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
      const randomNumber = Math.floor(Math.random() * 10000); // Optional: Add a random number at the end
      return `${randomAdjective}${randomNoun}${randomNumber}`;
    };

    useEffect(() => {
      setName(full_name);
    }, []);

    const genders = [
      "Male",
      "Female",
      "Non-Binary",
      "Other",
      "Prefer not to say",
    ];

    // State to track the selected gender
    const [selectedGender, setSelectedGender] = useState(gender_update);

    // Handle change in selected gender
    const handleGenderChange = (event) => {
      setSelectedGender(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1960; year <= currentYear; year++) {
      years.push(year);
    }
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const handleDateChange = (event) => {
      setSelectedDate(event.target.value);
    };
    const handleMonthChange = (event) => {
      setSelectedMonth(event.target.value);
    };
    const handleYearChange = (event) => {
      console.log(event.target.value);
      setSelectedYear(event.target.value);
    };

    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const [isOpen, setIsOpen] = useState(false); // To manage dropdown visibility
    // const [selectedGender, setSelectedGender] = useState("");
    const [headerText, setHeaderText] = useState("Select Gender"); // Header text

    // Handle toggle for dropdown
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    // Handle gender selection
    const handleSelect = (gender) => {
      setSelectedGender(gender);
      // setHeaderText(gender.charAt(0).toUpperCase() + gender.slice(1)); // Capitalize the first letter
      setIsOpen(false); // Close dropdown after selection
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={() => modal.hide()}
        onExited={() => modal.remove()}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <h4 className="mb-3 fw-normal">Update Profile </h4>
            <p
              className="text-center mb-0"
              style={{
                fontSize: "14px",
                color: "#818A97",
                whiteSpace: "normal",
              }}
            >
              This information is shared exclusively with
              <br />
              Thinktrek and will not be visible on your profile.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-row flex-wrap pt-1">
            <button
              style={{
                background: "#F2641F",
                width: 40,
                height: 40,
                borderRadius: 50,
                border: "none",
                margin: "0 5px",
                marginBottom: "10px",
                padding: 0,
              }}
            >
              {!preview ? (
                <IoCamera
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      avatar_id,
                      full_name,
                      gender_update,
                      date_of_birth,
                      profile_picture,
                      file,
                    });
                    modal.hide();
                  }}
                  style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
                />
              ) : (
                <img
                  style={{ borderRadius: 35, width: 40, height: 40 }}
                  onClick={() => {
                    NiceModal.show(Camera_Modal, {
                      avatar_id,
                      full_name,
                      gender_update,
                      date_of_birth,
                      profile_picture,
                      file,
                    });
                    modal.hide();
                  }}
                  src={preview}
                  width="100%"
                  height="100%"
                />
              )}
            </button>
            <div className="position-relative d-flex">
              <Uploader
                onChange={(event) => setFileList(event[0].blobFile)}
                fileListVisible={false}
                listType="picture"
                action="/"
                onUpload={(file) => {
                  setUploading(true);
                  previewFile(file.blobFile, (value) => {
                    setFileInfo(value);
                    //console.log(value);
                  });
                }}
              >
                <button
                  style={{
                    background: "#F2641F",
                    width: 40,
                    height: 40,
                    borderRadius: 50,
                    border: "none",
                    margin: "0 5px",
                    marginBottom: "10px",
                  }}
                >
                  {fileInfo ? (
                    <img src={fileInfo} width="100%" height="100%" />
                  ) : (
                    <ImageIcon style={{ fontSize: 15, color: "#fff" }} />
                  )}
                </button>
              </Uploader>
              <span className="fromGallery">
                <ImageIcon style={{ fontSize: 10, color: "#fff" }} />
              </span>
            </div>

            {Avatar_data.map((item, key) => {
              return (
                <AvatarGroup
                  spacing={6}
                  key={key}
                  className="position-relative"
                >
                  <Avatar
                    color="green"
                    bordered={selectedAvatar === item.avatar_id ? true : false}
                    key={key}
                    onClick={() => {
                      handleAvatarClick(item.avatar_id);
                    }}
                    style={{
                      cursor: "pointer",
                      margin: "0 5px",
                      marginBottom: "10px",
                    }}
                    circle
                    src={require(`../assets/avatars/${item.avatar_icon}`)}
                    alt=""
                  />
                  {selectedAvatar === item.avatar_id && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        bottom: "11px",
                        right: "0",
                        width: "13px",
                        height: "13px",
                        borderRadius: "3px",
                        background: "#2ED573",
                        position: "absolute",
                      }}
                    >
                      <TiTick
                        style={{ fontSize: "10px" }}
                        className="text-white"
                      />
                    </div>
                  )}
                </AvatarGroup>
              );
            })}
          </div>
          <div className="editField" style={{ marginTop: 10 }}>
            <label>Username*</label>
            <Input
              disabled={name_status ? true : false}
              value={name}
              placeholder="Fill username"
              onChange={handleUsername}
              style={{ background: "none" }}
            />
            {err && (
              <span style={{ fontSize: "10px", color: "red" }}>
                Enter username
              </span>
            )}
            <span
              className="editLink d-flex align-items-center"
              onClick={() => setName_status(false)}
            >
              Edit
            </span>
          </div>
          <div className="editField" style={{ marginTop: 10 }}>
            <label className="d-block">
              Date of birth
              <Whisper
                placement="top"
                controlId="control-id-click"
                trigger="click"
                speaker={tooltip}
              >
                <img
                  className="ms-2"
                  src={require("../assets/info-icon.png")}
                  width={15}
                  alt=""
                />
              </Whisper>
            </label>
            {/* <DatePicker
              placeholder="Not specified"
              disabled={dob_status ? true : false}
              oneTap
              placement="top"
              className="w-100"
              defaultValue={date_of_birth ? new Date(date_of_birth) : null}
              onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => setDob_status(false)}
            >
              Edit
            </span> */}
            <Input
              defaultValue={
                date_of_birth
                  ? new Date(date_of_birth).toISOString().split("T")[0]
                  : null
              }
              disabled={true}
              value={select_dob}
              placeholder="Not Specified"
              style={{ background: "none" }}
            />
            <span
              className="editLink d-flex align-items-center"
              onClick={() => {
                NiceModal.show(Update_Dob_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob,
                });
                modal.hide();
              }}
            >
              Edit
            </span>
          </div>
          <div
            className="editField"
            style={{ marginTop: 10 }}
            onClick={toggleDropdown}
          >
            <label className="d-block">Gender</label>
            <input
              readOnly
              type="text"
              placeholder="not specified"
              value={selectedGender}
            />

            <div className="custom-select-container">
              {isOpen && (
                <>
                  <div className="custom-select-header">{headerText}</div>
                  <ul className="custom-select-dropdown">
                    <li onClick={() => handleSelect("Male")}>Male</li>
                    <li onClick={() => handleSelect("Female")}>Female</li>
                    <li onClick={() => handleSelect("Others")}>Others</li>
                    <li onClick={() => handleSelect("Non-Binary")}>
                      Non-Binary
                    </li>
                    <li onClick={() => handleSelect("Prefer not to say")}>
                      Prefer not to say
                    </li>
                  </ul>
                </>
              )}
            </div>
            <span
              className="editLink d-flex align-items-center"
              style={{ pointerEvents: "none" }}
            >
              <img
                src={require("../assets/down-arrow.png")}
                width={15}
                alt=""
              />
            </span>
          </div>
          <div className="text-center">
            {!file &&
            !name &&
            !select_dob &&
            !selectedAvatar &&
            !selectedDate &&
            !selectedMonth &&
            !selectedYear &&
            !selectedGender &&
            !fileList ? (
              <Button
                className="guest-btn w-auto"
                onClick={() => {
                  onContactFromSubmit();
                  modal.hide();
                }}
                style={{
                  borderRadius: 20,
                  padding: "10px 25px",
                }}
              >
                Skip for Now
              </Button>
            ) : (
              <Button
                className="w-auto"
                onClick={onContactFromSubmit}
                loading={loading}
                appearance="primary"
                style={{
                  borderRadius: 20,
                  background: "#F2641F",
                  padding: "10px 25px",
                }}
              >
                Save & Continue
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Camera_Modal = NiceModal.create(
  ({
    avatar_id,
    full_name,
    gender_update,
    date_of_birth,
    profile_picture,
    file,
  }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
      const startCamera = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setStream(stream); // Store the stream
            setHasPermission(true);
          }
        } catch (err) {
          console.error("Error accessing camera: ", err);
          setHasPermission(false);
        }
      };

      // Start the camera when the component mounts
      startCamera();

      // Cleanup the stream on component unmount
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          const stream = videoRef.current.srcObject;
          // const tracks = stream.getTracks();
          // tracks.forEach((track) => track.stop());
        }
      };
    }, []);

    const stopCamera = () => {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop each track
        // setIsCameraOn(false); // Update the camera status
      }
      setHasPermission(false);
    };

    const captureImage = () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const context = canvas.getContext("2d");
        // Draw the current frame of the video onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas content to a Blob (image file)
        canvas.toBlob((blob) => {
          // Create a File from the Blob and append it to FormData
          const file = new File([blob], "captured-image.png", {
            type: "image/png",
          });
          const imageURL = canvas.toDataURL("image/png");
          //console.log(imageURL);
          //  const formData = new FormData();
          //  formData.append("image", file);
          NiceModal.show(Update_Profile_Modal, {
            avatar_id,
            full_name,
            gender_update,
            date_of_birth,
            profile_picture,
            file: file,
            preview: imageURL,
          });
          modal.hide();
          stopCamera();
          // Send the FormData to the server (for example, using fetch)
        }, "image/png"); // You can also use 'image/jpeg' for JPEG format
      }
    };

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            Camera
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row flex-wrap">
            <div className="w-100">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                autoPlay
                style={{ background: "#333947" }}
              />

              <canvas
                ref={canvasRef}
                style={{ display: "none" }}
                width="640"
                height="480"
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              onClick={captureImage}
              appearance="primary"
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: "#F2641F",
                padding: "10px 25px",
              }}
            >
              Capture
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Dob_Modal = NiceModal.create(
  ({ full_name, file, preview, select_dob }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              class="fa-solid fa-xmark"
              onClick={() => modal.hide()}
            ></i>
            Update Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="brthdy_sec">
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 10 }}>
                <label className="text-white d-block">Date of birth</label>
                <DatePicker
                  // value={dob}
                  placeholder={"DD/MM/YYYY"}
                  // disabled={dob_status ? true : false}
                  oneTap
                  size="md"
                  placement="bottom"
                  className="w-100"
                  onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
                />
                <span
                  className="editLink d-flex align-items-center"
                  style={{ pointerEvents: "none" }}
                >
                  <img
                    src={require("../assets/down-arrow.png")}
                    width={15}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              className={!dob && "guest-btn"}
              onClick={() => {
                NiceModal.show(Profile_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: dob && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Update_Dob_Modal = NiceModal.create(
  ({ full_name, file, preview, select_dob }) => {
    const modal = useModal();
    let cookies = new Cookies();
    let ACCESS_TOKEN_USER = cookies.get("login_token");
    const videoRef = useRef(null); // To reference the video element
    const canvasRef = useRef(null); // To reference the canvas element
    const streamRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [dob_status, setDob_status] = useState(true);

    return (
      <Modal
        className="rs-modal-xs"
        open={modal.visible}
        onClose={modal.hide}
        onExited={modal.remove}
      >
        <Modal.Header>
          <Modal.Title className="text-white" style={{ textAlign: "center" }}>
            <i class="fa-solid fa-xmark"></i>
            Update Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="brthdy_sec d-flex">
          <div className="d-flex flex-row flex-wrap ">
            <div className="w-100">
              <div className="editField" style={{ marginTop: 10 }}>
                <label className="text-white d-block">Date of birth</label>
                <DatePicker
                  // value={dob}
                  placeholder={"DD/MM/YYYY"}
                  // disabled={dob_status ? true : false}
                  oneTap
                  size="md"
                  placement="bottom"
                  className="w-100"
                  onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
                />
                <span
                  className="editLink d-flex align-items-center"
                  style={{ pointerEvents: "none" }}
                >
                  <img
                    src={require("../assets/down-arrow.png")}
                    width={15}
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center confirm">
            <Button
              className={!dob && "guest-btn"}
              onClick={() => {
                NiceModal.show(Update_Profile_Modal, {
                  full_name,
                  file,
                  preview,
                  select_dob: dob,
                });
                modal.hide();
              }}
              appearance={"primary"}
              style={{
                marginTop: 10,
                borderRadius: 20,
                background: dob && "#F2641F",
                padding: "10px 25px",
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
);

const Game_screen = () => {
  const iframeRef = useRef(null);
  let cookies = new Cookies();
  let ACCESS_GUEST_USER = cookies.get("token");
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [play, setPlay] = useState(false);
  const [stackList, setStackList] = useState([]);
  const [game_data, setGame_data] = useState({});
  const [like_dislike_data, setLikeDisklike] = useState([]);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [fileInfo, setFileInfo] = useState(null);
  const [status, setStatus] = useState(0);
  const [username, setUsername] = useState("");
  const [score_conversion, setScore_conversion] = useState([]);
  const {
    profile_status_load,
    add_profile_load,
    token,
    status_guest,
    add_guest_status,
  } = useStore();

  let [id, setId] = useState("");
  let [game_score, setGame_score] = useState("");

  const tooltip = (
    <Tooltip>
      As a guest you are agreeing to the terms that you are 13+ years of age. To
      get the advantages and special privilages of partcipating in the Weekly
      Leaderboard Challenges, you need to Login.
    </Tooltip>
  );

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const network_details = cookies.get("network_details");
  let ACCESS_TOKEN_USER = cookies.get("login_token");

  useEffect(() => {
    // if (!ACCESS_TOKEN_USER) {
    //   NiceModal.show(Disclaimer_Modal);
    // }
    visitor_details();
  }, []);

  useEffect(() => {
    visitor_details();
  }, [profile_status_load]);

  const visitor_details = () => {
    Service.visitor_details()
      .then((res) => {
        let date = res?.data?.created_at;
        if (res.status == 1) {
          setFileInfo(res?.data?.profile_picture);
          setPercent(res?.data?.profile_completion_percentage);
          setAvatar_id(res?.data?.avatar_id);
          setUsername(res?.data?.full_name);
        }
      })
      .catch((e) => console.log(e));
  };

  const filteredAvatars = Avatar_data?.filter(
    (avatar) => avatar?.avatar_id === avatar_id
  );

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const handleInputChange = (event) => {
    // setSearch(event);
    //console.log(event.target);
    if (!event) {
      setVisible(false);
      // on_game_list();
    } else if (event) {
      setVisible(true);
    }
    let params = {
      query: event,
    };
    on_search(params);
  };
  const on_search = (params) => {
    Service.search(params)
      .then((res) => {
        // console.log(res.data);
        setSearch_data(res?.data);
      })
      .catch((e) => e.message);
  };

  useEffect(() => {
    logEvent(analytics, "games_most_categories_visited_notplayed", {
      game_id: location?.state?.datas?.id,
      game_title: location?.state?.datas?.title,
      game_category: location?.state?.datas?.category,
      game_technology: location?.state?.datas?.technology,
      country: network_details?.country_name,
    });
    logEvent(analytics, "games_technologies_visited_notplayed", {
      game_id: location?.state?.datas?.id,
      game_title: location?.state?.datas?.title,
      game_category: location?.state?.datas?.category,
      game_technology: location?.state?.datas?.technology,
      country: network_details?.country_name,
    });
    window.scrollTo(0, 0);
    // window.location.reload();
    suggest_list(location?.state?.datas?.id);
    category_techology_list();
    // game_details();
  }, [location?.state?.datas]);

  useEffect(() => {
    game_details();
  }, [location?.state?.datas?.id]);

  const suggest_list = (id) => {
    Service.suggest_game_list(id)
      .then((res) => {
        // console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => console.log(e.message));
  };
  const category_techology_list = () => {
    Service.category_technology_list()
      .then((res) => {
        // console.log(res.data);
        setStackList(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const game_details = () => {
    let params = {
      gameId: location?.state?.datas?.id,
    };
    Service.game_details(params)
      .then((res) => {
        //console.log(res);
        if (res?.status == 1) {
          setStatus(res?.status);
          setGame_data(res?.data);
          setScore_conversion(res?.data?.score_conversion);
        }

        //setCategory(res?.data?.categories);
      })
      .catch((e) => console.log(e.message));
  };

  const score_Session = (id, score) => {
    let params = {
      game_id: id,
      game_score: score,
    };
    Service.score_update(params)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  };

  let login_token = cookies.get("login_token");
  let [guest_status, setGuest_status] = useState(status_guest);

  useEffect(() => {
    if (login_token) {
      score_Session(id, game_score);
      setGuest_status(false);
    }
  }, [login_token]);
  useEffect(() => {
    const handleMessage = (event) => {
      // if (event.origin !== game_data.game_url) return;

      console.log("EVENT JSBRIDGE REACTSIDE===>", event.data);

      if (event.data.eventName == "GameFrequency") {
        let id = event.data?.data?.game_id;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "gameplayed_count", {
          game_id: id,
          game_title: title,
          game_type: type,
          country: network_details?.country_name,
          game_category: location?.state?.datas?.category,
        });
        logEvent(analytics, "games_most_categories_visited_played", {
          game_id: id,
          game_title: title,
          game_type: type,
          country: network_details?.country_name,
          game_category: location?.state?.datas?.category,
        });
        logEvent(analytics, "games_most_technologies_visited_played", {
          game_id: id,
          game_title: title,
          game_type: type,
          country: network_details?.country_name,
          game_technology: location?.state?.datas?.technology,
        });
      } else if (event.data.eventName == "GameOver") {
        //console.log(event.data.eventName);
        let id = event.data?.data?.game_id;
        let score = event.data?.data?.score;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "games_started_completed", {
          game_id: id,
          game_title: title,
          game_type: type,
          game_category: location?.state?.datas?.category,
          country: network_details?.country_name,
        });
        if (!login_token) {
          setGuest_status(true);
          // add_guest_status("1");
          setGame_score(score);
          setId(id);
        } else if (login_token) {
          score_Session(id, score);
        }
      } else if (event.data.eventName == "GameQuit") {
        //console.log(event.data.eventName);
        let id = event.data?.data?.game_id;
        let score = event.data?.data?.score;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "games_started_notcompleted", {
          game_id: id,
          game_title: title,
          game_type: type,
          game_category: location?.state?.datas?.category,
          country: network_details?.country_name,
        });
        if (!login_token) {
          setGuest_status(true);
          // add_guest_status("1");
          setGame_score(score);
          setId(id);
        } else if (login_token) {
          score_Session(id, score);
        }
      } else if (event.data.eventName == "GameCompleted") {
        //console.log(event.data.eventName);
        let id = event.data?.data?.game_id;
        let score = event.data?.data?.score;
        let title = event.data?.data?.game_name;
        let type = event.data?.data?.game_type;
        logEvent(analytics, "games_started_completed", {
          game_id: id,
          game_title: title,
          game_type: type,
          game_category: location?.state?.datas?.category,
          country: network_details?.country_name,
        });
        if (!login_token) {
          setGuest_status(true);
          // add_guest_status("1");
          setGame_score(score);
          setId(id);
        } else if (login_token) {
          score_Session(id, score);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [game_data?.game_url, login_token]);

  const like_dislike = (action) => {
    let params = {
      gameId: location?.state?.datas?.id,
      action: action,
    };
    Service.like_dislike_action(params)
      .then((res) => {
        setLikeDisklike(res?.data);
        //console.log("details", res?.data[0]);
      })
      .catch((e) => console.log(e.message));
  };

  const fullscreen = () => {
    // check if fullscreen mode is available
    if (
      document.fullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled
    ) {
      // which element will be fullscreen
      var iframe = document.querySelector("iframe");

      // Do fullscreen
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.webkitRequestFullscreen) {
        iframe.webkitRequestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        iframe.mozRequestFullScreen();
      } else if (iframe.msRequestFullscreen) {
        iframe.msRequestFullscreen();
      }
    } else {
      document.querySelector(".error").innerHTML =
        "Your browser is not supported";
    }
  };

  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const fav_status = (status) => {
    let params = {
      game_id: game_data?.id,
      status: status,
    };
    Service.fav_games_status(params)
      .then((res) => {
        //console.log(res);
        if (res.status == 1) {
          game_details();
        }
      })
      .catch((e) => console.log(e));
  };

  let [ad_data_portrait, setAd_data_portrait] = useState([]);
  let [ad_data_landscape, setAd_data_landscape] = useState([]);
  let [ad_data_rectangle, setAd_data_rectangle] = useState([]);

  useEffect(() => {
    ads_fetch();
  }, []);
  const ads_fetch = () => {
    let params = {
      category_name: "",
    };
    Service.custom_ads(params)
      .then((res) => {
        // setData(res.data);
        const filteredPortraitAds = res?.data?.filter(
          (ad) => ad?.orientation === "portrait"
        );
        const filteredLandscapeAds = res?.data?.filter(
          (ad) => ad?.orientation === "landscape"
        );
        const filteredRectangleAds = res?.data?.filter(
          (ad) => ad?.orientation === "rectangular"
        );
        console.log({ filteredRectangleAds });
        setAd_data_portrait(filteredPortraitAds);
        setAd_data_landscape(filteredLandscapeAds);
        setAd_data_rectangle(filteredRectangleAds);
      })
      .catch((e) => console.log(e));
  };

  const aspectRatio = calculateAspectRatio(
    window.innerWidth,
    window.innerHeight
  );
  let dimension = window.innerWidth / window.innerHeight;
  let height_percent = (window.innerHeight * 90) / 100;
  console.log(
    window.innerHeight,
    height_percent,
    window.innerWidth,
    window.innerHeight
  );
  let height = height_percent / dimension;
  let width = height / dimension;

  return (
    <div>
      <Header onPress={toggleMenu} />
      <div className="container-fluid pl-80 d-flex flex-column mh-100vh">
        <div className="row">
          {/* NAVIGATION */}
          <div className={`navigation ${isActive ? "active" : ""}`}>
            <p
              className="m-2 ps-5 d-flex align-items-center d-md-none"
              style={{ color: "#f2641f" }}
            >
              {ACCESS_TOKEN_USER
                ? username?.length > 17
                  ? username?.substring(0, 17 - 1) + ""
                  : username
                : "Guest User"}

              {!ACCESS_TOKEN_USER && (
                <Whisper
                  placement="bottom"
                  controlId="control-id-click"
                  trigger="click"
                  speaker={tooltip}
                >
                  <img
                    className="ms-2"
                    src={require("../assets/info-icon.png")}
                    width={15}
                    alt=""
                  />
                </Whisper>
              )}
            </p>
            <ul>
              <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins1/home.png")}
                  />
                </span>
                <span className="title">Home</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/new_games", {
                    state: {
                      flag: "new_game",
                      name: "New Game",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins1/star.png")}
                  />
                </span>
                <span className="title">New Games</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/recent_games", {
                    state: {
                      flag: "recent_game",
                      name: "Recently Played",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins1/recent.png")}
                  />
                </span>
                <span className="title">Recently Played</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/updated", {
                    state: {
                      flag: "updated",
                      name: "Updated",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins1/updated.png")}
                  />
                </span>
                <span className="title">Updated</span>
              </li>
            </ul>

            <ul className="mt-5">
              {stackList?.categories?.map((item, index) => {
                //console.log(item.game_img);
                return (
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      logEvent(analytics, "games_most_categories_visited", {
                        category_id: item?.category_id_,
                        category_name: item?.category_name,
                      });
                      logEvent(analytics, "mostgames_categories_countries", {
                        category_name: item?.category_name,
                        country: network_details?.country_name,
                        city: network_details?.city,
                      });
                      navigate(
                        `/category/${item?.category_id}/${item?.category_name}`,
                        {
                          state: {
                            datas: item,
                            name: item?.category_name,
                            flag: "category",
                          },
                        }
                      );
                    }}
                  >
                    <span className="icon">
                      <img
                        key={index}
                        style={{ cursor: "pointer" }}
                        src={item?.icon}
                        className="color1"
                      />
                    </span>
                    <span className="title">{item.category_name}</span>
                  </li>
                );
              })}
            </ul>

            <ul className="mt-5">
              {stackList?.technologies?.map((item, index) => {
                //console.log(item.game_img);
                return (
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      logEvent(analytics, "games_most_technologies_visited", {
                        technology_id: item?.technology_id,
                        technology_name: item?.technology_name,
                      });
                      logEvent(analytics, "mostgames_technology_countries", {
                        technology_name: item?.technology_name,
                        country: network_details?.country_name,
                        city: network_details?.city,
                      });
                      navigate(
                        `/technology/${item?.technology_id}/${item?.technology_name}`,
                        {
                          state: {
                            datas: item,
                            name: item?.technology_name,
                            flag: "technology",
                          },
                        }
                      );
                    }}
                  >
                    <span className="icon">
                      <img
                        key={index}
                        style={{ cursor: "pointer" }}
                        src={item?.icon}
                        className="color1"
                      />
                    </span>
                    <span className="title">{item.technology_name}</span>
                  </li>
                );
              })}
            </ul>

            <ul className="otherLink">
              <li
                onClick={() =>
                  navigate("/terms_and_conditions", {
                    state: {
                      flag: "t&c",
                      name: "Terms and Conditions",
                    },
                  })
                }
              >
                <span>Terms & Conditions</span>
              </li>
              <li
                onClick={() =>
                  navigate("/privacy_policy", {
                    state: {
                      flag: "policy",
                      name: "Privacy Policy",
                    },
                  })
                }
              >
                <span>Privacy</span>
              </li>
            </ul>
          </div>
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col-12 col-lg-12 col-xl-12 mt-lg-4">
            <div className="row justify-content-between mb-0 mb-lg-0">
              <div
                className={
                  // game_data?.game_orientation == "portrait"
                  //   ? "col-12 col-md-2 col-lg-3 d-none d-md-block "
                  //   :
                  "col-12 col-md-2 col-lg-3 d-none"
                }
              >
                {/* {game_data?.game_orientation == "portrait" && (
                  // <img
                  //   src={require("./skins1/screenshot_2024_09_25_191006.png")}
                  //   className="screenshot_2024_09_25_191006_portrait w-100"
                  // />
                  <Custom_ads
                    ads={ad_data_portrait}
                    classname={"screenshot_2024_09_25_191006_portrait w-100"}
                    rotationInterval={5000}
                  />
                )} */}
              </div>
              <div
                className={
                  // game_data?.game_orientation == "portrait"
                  //   ? "col-12 col-md-8 col-lg-4 mb-5 mb-sm-0 d-flex justify-content-center position-relative "
                  //   :

                  "col-12 col-md-8 col-lg-7 col-xl-7 col-xxl-8 mb-3 mb-sm-0 d-flex justify-content-start position-relative flex-column"
                }
              >
                <div className="game_lt_panel">
                  {!play && (
                    <>
                      <div
                        className="gameOverlay d-flex align-items-center justify-content-center flex-column"
                        onClick={() => {
                          setPlay(true);
                        }}
                      >
                        <h2>{game_data?.title}</h2>
                        <div
                          className="playBtn d-flex align-items-center justify-content-between"
                          onClick={() => {
                            setPlay(true);
                            if (isMobile) {
                              fullscreen();
                            }
                          }}
                        >
                          Play Now
                          <img
                            src={require("./skins1/play_button.png")}
                            className={
                              // game_data?.game_orientation == "portrait"
                              //   ? "play_button"
                              //   :
                              "play_button_landscape"
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {guest_status && (
                    <div className="gameOverlay d-flex align-items-center justify-content-center flex-column">
                      <img style={{ width: 120 }} src={Nostr} alt="" />

                      <p
                        className="text-center"
                        style={{
                          padding: "0 10px",
                          marginTop: 30,
                          fontSize: 12,
                        }}
                      >
                        By participating as a guest, your score will not be
                        updated on the leaderboard, and you might miss the
                        opportunity to earn rewards.
                      </p>

                      <div className="text-center">
                        <Button
                          // onKeyDown={handleKeyDown}
                          className="my-4"
                          // loading={loading}
                          onClick={() => {
                            NiceModal.show(Phone_Modal, {
                              status: true,
                            });
                          }}
                          // disabled={!phone ? true : false}
                          appearance="primary"
                          style={{
                            width: "auto",
                            marginTop: 10,
                            borderRadius: 20,
                            background: "#F2641F",
                            padding: "10px 22px",
                          }}
                        >
                          Play Now
                        </Button>
                        <p
                          className="text-secondary"
                          style={{ justifyItems: "flex-end", flex: 1 }}
                        >
                          OR
                        </p>
                        <div style={{ marginTop: 10 }}></div>
                        <Button
                          className="guest-btn"
                          onClick={() => {
                            setGuest_status(false);
                          }}
                          appearance="primary"
                          style={{
                            marginTop: 10,
                            borderRadius: 20,
                            width: "auto",
                          }}
                          block
                        >
                          Continue as Guest
                        </Button>
                      </div>
                    </div>
                  )}
                  {status == 1 ? (
                    <iframe
                      className={
                        // game_data?.game_orientation == "portrait"
                        //   ? "icon_003"
                        //:
                        "icon_003_landscape"
                      }
                      src={`${game_data?.game_url}?game_id=${game_data?.id}`}
                      allowFullScreen
                      style={{
                        aspectRatio: "16/9",
                        // game_data?.game_orientation == "portrait"
                        //   ? "9/16"
                        //   : "16/9",
                      }}
                      // style={{
                      //   width: width,
                      //   height: height,
                      //   // aspectRatio:
                      //   //   game_data?.game_orientation == "portrait"
                      //   //     ? "9/16"
                      //   //     : "16/9",
                      // }}
                      scrolling="no"
                    />
                  ) : null}

                  <div className="iframeCtrl d-flex align-items-center justify-content-between">
                    <div className="gameName">{game_data?.title}</div>
                    <div className="d-flex align-items-center">
                      <span className="me-2 me-sm-2 me-lg-4">
                        {game_data?.is_favourite == 1 ? (
                          <FaHeart
                            onClick={() => {
                              if (ACCESS_TOKEN_USER) {
                                fav_status(0);
                              } else if (!ACCESS_TOKEN_USER) {
                                NiceModal.show(Phone_Modal);
                              }
                            }}
                            style={{
                              color: "#F2641F",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <FaHeart
                            onClick={() => {
                              if (ACCESS_TOKEN_USER) {
                                logEvent(analytics, "games_favourites_count", {
                                  game_id: game_data?.id,
                                  game_title: game_data?.title,
                                  game_technology: game_data?.technologies,
                                  game_category: game_data?.categories,
                                  game_platform: game_data?.platforms,
                                  country: network_details?.country_name,
                                });
                                fav_status(1);
                              } else if (!ACCESS_TOKEN_USER) {
                                NiceModal.show(Phone_Modal);
                              }
                            }}
                            style={{
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </span>

                      <span
                        className="me-2 me-sm-2 me-lg-4"
                        style={{
                          color:
                            // like_dislike_data[0]?.total_liked.length == 1
                            //   ? "#2ED573"
                            //:
                            "#fff",
                        }}
                      >
                        <svg
                          className="me-1"
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            fill:
                              // like_dislike_data[0]?.total_liked.length == 1
                              // ? "#2ED573"
                              //:
                              "#fff",
                          }}
                          onClick={() => {
                            logEvent(analytics, "games_liked_count", {
                              game_id: game_data?.id,
                              game_title: game_data?.title,
                              game_technology: game_data?.technologies,
                              game_category: game_data?.categories,
                              game_platform: game_data?.platforms,
                              country: network_details?.country_name,
                            });
                            like_dislike("like");
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2l144 0c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48l-97.5 0c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3l0-38.3 0-48 0-24.9c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192l64 0c17.7 0 32 14.3 32 32l0 224c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32L0 224c0-17.7 14.3-32 32-32z" />
                        </svg>{" "}
                        {/* <img
                        onClick={() => like_dislike("like")}
                        style={{ cursor: "pointer", width: "16px" }}
                        src={require("./skins1/thumbs_up.png")}
                        className="me-1"
                      /> */}
                        {like_dislike_data[0]?.total_liked ||
                          game_data?.total_likes}
                      </span>
                      <span className="me-2 me-sm-2 me-lg-4">
                        <svg
                          className="me-1"
                          onClick={() => {
                            logEvent(analytics, "games_unliked_count", {
                              game_id: game_data?.id,
                              game_title: game_data?.title,
                              game_technology: game_data?.technologies,
                              game_category: game_data?.categories,
                              game_platform: game_data?.platforms,
                              country: network_details?.country_name,
                            });
                            like_dislike("dislike");
                          }}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            fill: "#fff",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2l144 0c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48l-97.5 0c-19 0-37.5 5.6-53.3 16.1L202.7 73.8C176 91.6 160 121.6 160 153.7l0 38.3 0 48 0 24.9c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7zM32 384l64 0c17.7 0 32-14.3 32-32l0-224c0-17.7-14.3-32-32-32L32 96C14.3 96 0 110.3 0 128L0 352c0 17.7 14.3 32 32 32z" />
                        </svg>{" "}
                        {/* <img
                        onClick={() => like_dislike("dislike")}
                        style={{ cursor: "pointer", width: "16px" }}
                        src={require("./skins1/thumbs_down.png")}
                        className="me-1"
                      /> */}
                        {like_dislike_data[0]?.total_disliked ||
                          game_data?.total_dislikes}
                      </span>
                      <span>
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={fullscreen}
                          src={require("./skins1/path_26439.png")}
                          className={
                            // game_data?.game_orientation == "portrait"
                            //   ? "path_26439"
                            //   :
                            "path_26439_landscape"
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-5 pt-3 left_add">
                  <Custom_ads
                    ads={ad_data_landscape}
                    rotationInterval={5000}
                    classname={"w-100"}
                  />
                </div>

                <div className="row mt-5">
                  <div className="col-12 gameInfo mb-4 mb-lg-0 ">
                    <h2>{game_data?.title}</h2>
                    <p>{game_data?.description}</p>
                  </div>
                  <div className="col-sm-12 col-xxl-5 col-xl-5 col-lg-5 col-md-6 gameInfo">
                    <table className="">
                      {/* <tr>
                        <td>Score 0-10</td>
                        <td>Get <img style={{ padding: "0 6px" }} src={star} alt="" /> 2</td>
                      </tr> */}
                      <tr>
                        <td>Rating:</td>
                        <td>{`${game_data?.admin_rating} (${
                          game_data?.total_likes + game_data?.total_dislikes
                        } votes)`}</td>
                      </tr>
                      <tr>
                        <td>Developer:</td>
                        <td>{game_data?.developer}</td>
                      </tr>
                      <tr>
                        <td>Released:</td>
                        <td>
                          {moment(game_data?.released).format("MMMM YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <td>Technology:</td>
                        <td>{game_data?.technologies}</td>
                      </tr>
                      <tr>
                        <td>Platform:</td>
                        <td>{game_data?.platforms}</td>
                      </tr>
                      {/* <tr>
                        <td>Graphics:</td>
                        <td>{game_data?.graphic}</td>
                      </tr> */}
                      {/* <tr>
                        <td>Preview:</td>
                        <td>
                          <ReactPlayer
                            className="videoThumb"
                            playing={true}
                            //controls
                            volume={0}
                            loop={true}
                            muted={true}
                            url={game_data?.video_upload}
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td>Points Conversion:</td>
                        <td>
                          <table className="m-0">
                            <tr>
                              <td>Score</td>
                              <td>Point</td>
                            </tr>
                            {score_conversion.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      {`${item?.min_value} - ${item?.max_value} `}
                                    </td>
                                    <td>{item?.equivalent_point}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-sm-12 col-xxl-7 col-xl-7 col-lg-7 col-md-6 gameInfo">
                    <h6 style={{ color: "#9698a7" }}>Game Preview:</h6>
                    <p>
                      <ReactPlayer
                        className="videoThumb"
                        playing={true}
                        //controls
                        volume={0}
                        loop={true}
                        muted={true}
                        url={game_data?.video_upload}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 col-lg-3 col-xl-3 col-xxl-2 mt-5 mt-sm-0 d-none d-md-block">
                {/* <img
                  src={require("./skins1/screenshot_2024_09_25_191006.png")}
                  className="screenshot_2024_09_25_191006 w-100"
                /> */}
                <div className="add1">
                  <Custom_ads
                    ads={ad_data_portrait}
                    rotationInterval={5000}
                    classname={"screenshot_2024_09_25_191006 w-100"}
                  />
                </div>

                {/* <div className="advertise_sec">
                  <Custom_ads
                    ads={ad_data_rectangle}
                    rotationInterval={5000}
                    classname={"w-100"}
                  />
                </div> */}
              </div>
              <div className="col-sm-12 col-xxl-2 col-xl-2 col-lg-2 col-md-2 position-relative">
                {/* <div className="col-12">
                  {data?.length !== 0 && (
                    <div className="catgoryTitle">Action Games</div>
                  )}
                </div> */}
                <div className="col mb-3 related_game_sec">
                  {/* <Swiper
                    navigation={true}
                    modules={[Scrollbar, Navigation]}
                    spaceBetween={10}
                    slidesPerView={6}
                    // scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                      320: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      480: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      640: {
                        slidesPerView: ,
                        spaceBetween: 15,
                      },
                      768: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                      1024: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                    }}
                    className="featureSwiper"
                  >

                  </Swiper> */}

                  {data?.map((item, index) => {
                    //console.log(item);
                    return (
                      <div className="col-sm-12">
                        {item.tag == "new" && (
                          <div className="new-badge">New</div>
                        )}
                        {item.last_updated && (
                          <div className="update-badge">Updated</div>
                        )}
                        <div className="gameItem">
                          <HoverVideoPlayer
                            onClick={() =>
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                            className="connect_the_ball_ek11 gameThumb"
                            restartOnPaused
                            overlayTransitionDuration={250}
                            videoSrc={item?.video_url || item?.video_upload}
                            //videoSrc="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                            pausedOverlay={
                              <img
                                src={item?.cover_picture}
                                alt=""
                                className="connect_the_ball_ek11 w-100 h-100"
                              />
                            }
                            loadingOverlay={
                              <div className="loading-overlay">
                                <div className="loading-spinner" />
                              </div>
                            }
                          />
                          <div className="hoverText">{item?.title}</div>
                          {/* <img
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              })
                            }
                            src={item?.cover_picture}
                            //src={require("./skins1/connect_the_ball.png")}
                            className="connect_the_ball_ek11 w-100"
                          /> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2 mt-lg-3 mt-xxl-5">
            <div className="row mt-lg-2 pt-lg-4">
              <div
                className={
                  // game_data?.game_orientation == "portrait"
                  //   ? "col-12 d-none"
                  //   :

                  "col-12 col-md-8 mb-3 d-block"
                }
              >
                {/* <img
                  src={require("./skins1/screenshot_2024_09_25_190613.png")}
                  className="w-100"
                /> */}
                {/* <Custom_ads
                  ads={ad_data_landscape}
                  rotationInterval={5000}
                  classname={"w-100"}
                /> */}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 mb-3 mob_swipe">
                <Swiper
                  navigation={true}
                  modules={[Scrollbar, Navigation]}
                  spaceBetween={10}
                  slidesPerView={6}
                  // scrollbar={{ draggable: true }}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 6,
                      spaceBetween: 10,
                    },
                  }}
                  className="featureSwiper"
                >
                  {data?.map((item, index) => {
                    //console.log(item);
                    return (
                      <SwiperSlide>
                        {item.tag == "new" && (
                          <div className="new-badge">New</div>
                        )}
                        {item.last_updated && (
                          <div className="update-badge">Updated</div>
                        )}
                        <div className="gameItem">
                          <HoverVideoPlayer
                            onClick={() =>
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              })
                            }
                            style={{ cursor: "pointer" }}
                            className="connect_the_ball_ek11 gameThumb"
                            restartOnPaused
                            overlayTransitionDuration={250}
                            videoSrc={item?.video_url || item?.video_upload}
                            //videoSrc="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                            pausedOverlay={
                              <img
                                src={item?.cover_picture}
                                alt=""
                                className="connect_the_ball_ek11 w-100 h-100"
                              />
                            }
                            loadingOverlay={
                              <div className="loading-overlay">
                                <div className="loading-spinner" />
                              </div>
                            }
                          />
                          <div className="hoverText">{item?.title}</div>
                          {/* <img
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              })
                            }
                            src={item?.cover_picture}
                            //src={require("./skins1/connect_the_ball.png")}
                            className="connect_the_ball_ek11 w-100"
                          /> */}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <footer>
              <div className="container-fluid border_top">
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    {/* <p className="d-block d-lg-flex align-items-center justify-content-center">
                      Development Partner
                      <img src={require("./skins1/rat.png")} alt="" />
                      <a href="https://redappletech.com/" target="_blank">
                        <span>Red Apple Technology </span>
                      </a>
                      &nbsp; | Learning Partner
                      <img src={require("./skins1/ral.png")} alt="" />
                      <a href="https://redapplelearning.in/" target="_blank">
                        <span>Red Apple Learning</span>
                      </a>
                    </p> */}
                    <p>
                      Copyright &copy;{" "}
                      <a
                        className="text-white"
                        href="https://thinktrekent.in/"
                        target="_blank"
                      >
                        Thinktrek Entertainment
                      </a>
                    </p>
                    <ul className="footerlist">
                      <li
                        onClick={() =>
                          navigate("/terms_and_conditions", {
                            state: {
                              flag: "t&c",
                              name: "Terms and Conditions",
                            },
                          })
                        }
                      >
                        <span>Terms & Conditions</span>
                      </li>
                      <li
                        onClick={() =>
                          navigate("/privacy_policy", {
                            state: {
                              flag: "policy",
                              name: "Privacy Policy",
                            },
                          })
                        }
                      >
                        <span>Privacy</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
          </div>
          {/* PAGE CONTENT */}
        </div>
      </div>
    </div>
  );
};

export default Game_screen;
