import APIs from "./serve";
import Cookies from "universal-cookie";

let cookie = new Cookies();

const Service = {
  login_register_visitor: function (params) {
    return new Promise((resolve, reject) => {
      APIs.post(`/api/register-or-login-visitor`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  search: function (params) {
    return new Promise((resolve, reject) => {
      APIs.get(`/api/search-game-globally`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  game_list: function (params) {
    return new Promise((resolve, reject) => {
      APIs.post(`/api/game-list`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  game_details: function (params) {
    let ACCESS_TOKEN = cookie.get("token");
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/game-details`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER || ACCESS_TOKEN}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  like_dislike_action: function (params) {
    let ACCESS_TOKEN = cookie.get("token");
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/visitor-like-dislike-games`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER || ACCESS_TOKEN}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  recent_games: function (params) {
    let ACCESS_TOKEN = cookie.get("token");
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.get(`/api/recently-played-games`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER || ACCESS_TOKEN}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  category_wise_game_list: function (params) {
    return new Promise((resolve, reject) => {
      APIs.get(`/api/categorywise-game`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  suggest_game_list: function (id) {
    return new Promise((resolve, reject) => {
      APIs.get(`/api/suggest-games/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  category_technology_list: function (id) {
    return new Promise((resolve, reject) => {
      APIs.post(`/api/list-game-cat-and-tech`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  new_added_games: function (id) {
    return new Promise((resolve, reject) => {
      APIs.get(`/api/list-newly-added-games`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updated_games: function (id) {
    return new Promise((resolve, reject) => {
      APIs.get(`/api/updated-game-list`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  login_number: function (params) {
    return new Promise((resolve, reject) => {
      APIs.post(`/api/get-otp-by-phonenumber`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  verify_otp: function (params) {
    return new Promise((resolve, reject) => {
      APIs.post(`/api/verify-visitors-otp`, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  score_update: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/save-game-session`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  leaderboard: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/get-leaderboard-data`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  playing_history: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/visitor-playing-data`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  visitor_details: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.get(`/api/get-visitor-profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fav_games: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.get(`/api/fav-games-list-by-visitor`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fav_games_status: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/update-fav-game-status`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  recent_games_status: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/remove-game-from-recent-played-list`, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  custom_ads: function (params) {
    let ACCESS_TOKEN_USER = cookie.get("login_token");
    return new Promise((resolve, reject) => {
      APIs.post(`/api/get-advertisement-list-for-frontend`, params, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
        },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
export default Service;
