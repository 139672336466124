import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import "./game_stack.css";
import { Input, InputGroup, MaskedInput } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { FlexboxGrid } from "rsuite";
import { Grid, Row, Col } from "rsuite";
import Service from "../service/apis";
import Logo from "../home_screen/skins/logo.png";
import HoverVideoPlayer from "react-hover-video-player";
import { Modal, Drawer, Button, HStack } from "rsuite";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { alert, confirm, prompt } from "@rsuite/interactions";
import {
  Divider,
  Avatar,
  Uploader,
  DatePicker,
  Radio,
  RadioGroup,
  useToaster,
  AvatarGroup,
  Progress,
  DateRangePicker,
  Whisper,
  Tooltip,
} from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import moment from "moment";
import Cookies from "universal-cookie";
import { List } from "rsuite";
import PhoneInput from "react-phone-input-2";
import { IoCamera } from "react-icons/io5";
import ImageIcon from "@rsuite/icons/Image";
import axios from "axios";
import OtpInput from "react-otp-input";
import Avatar_data from "../avatars.json";
import { TiTick } from "react-icons/ti";
import { endOfWeek, startOfWeek } from "rsuite/esm/internals/utils/date";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Flag from "react-world-flags";
import useStore from "../service/zustand";
import Countdown from "react-countdown";
import Header from "../Header";
import CCalendar from "../CCalendar";

let BASE_URL = process.env.REACT_APP_BASE_URL_V2;

// const Profile_Modal = NiceModal.create(({ full_name, file, preview }) => {
//   const modal = useModal();
//   let cookies = new Cookies();
//   let ACCESS_TOKEN_USER = cookies.get("login_token");
//   const toaster = useToaster();
//   const [uploading, setUploading] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [fileInfo, setFileInfo] = useState(null);
//   const [fileList, setFileList] = useState(null);
//   const [err, setErr] = useState(false);
//   const [name, setName] = useState("");
//   const [gender, setGender] = useState("");
//   const [dob, setDob] = useState("");
//   const [phone, setPhone] = useState("");
//   const [selectedAvatar, setSelectedAvatar] = useState(null);
//   const [dob_status, setDob_status] = useState(true);
//   const [name_status, setName_status] = useState(true);
//   const tooltip = (
//     <Tooltip>
//       If DOB is not declared then you are agreeing to the terms that you are 13+
//       years of age. To get the advantages and special privilages of partcipating
//       in the Weekly Leaderboard Challenges, you need to Login.
//     </Tooltip>
//   );
//   // Function to handle avatar selection
//   const handleAvatarClick = (index) => {
//     console.log({ index });
//     setSelectedAvatar(index);
//   };
//   function previewFile(file, callback) {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(file);
//   }
//   const onChange = (date, dateString) => {
//     console.log(date, dateString);
//   };
//   const onContactFromSubmit = async () => {
//     setLoading(true);
//     if (!name) {
//       setErr(true);
//       setLoading(false);
//       return;
//     }
//     setErr(false);
//     let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
//     let form = new FormData();
//     form.append("full_name", name);
//     form.append("profile_picture", file || fileList);
//     form.append("date_of_birth", dob || "");
//     form.append("gender", selectedGender || "");
//     form.append("avatar_id", selectedAvatar || "");
//     try {
//       await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
//         method: "POST",
//         body: form,
//         headers: {
//           //  'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
//         },
//       })
//         .then((res) => res.json())
//         .then((data) => {
//           if (data.status == 0) {
//             setLoading(false);
//           }
//           if (data.status == 1) {
//             setLoading(false);
//             modal.hide();

//             window.location.reload(true);
//           }
//         })
//         .catch((e) => e.message);
//     } catch (e) {
//       throw e;
//     }
//   };

//   const handleUsername = (event) => {
//     setName(event);
//   };

//   useEffect(() => {
//     setName(full_name);
//   }, []);

//   const genders = [
//     "Male",
//     "Female",
//     "Non-Binary",
//     "Other",
//     "Prefer not to say",
//   ];

//   // State to track the selected gender
//   const [selectedGender, setSelectedGender] = useState("");

//   // Handle change in selected gender
//   const handleGenderChange = (event) => {
//     setSelectedGender(event.target.value);
//   };

//   const currentYear = new Date().getFullYear();
//   const years = [];
//   for (let year = 1960; year <= currentYear; year++) {
//     years.push(year);
//   }
//   const [selectedYear, setSelectedYear] = useState("");
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [selectedDate, setSelectedDate] = useState("");

//   const handleDateChange = (event) => {
//     setSelectedDate(event.target.value);
//   };
//   const handleMonthChange = (event) => {
//     setSelectedMonth(event.target.value);
//   };
//   const handleYearChange = (event) => {
//     console.log(event.target.value);
//     setSelectedYear(event.target.value);
//   };

//   const days = Array.from({ length: 31 }, (_, index) => index + 1);

//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   return (
//     <Modal
//       className="rs-modal-xs"
//       open={modal.visible}
//       onClose={modal.hide}
//       onExited={modal.remove}
//     >
//       <Modal.Header>
//         <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//           <h4 className="mb-3 fw-normal">Update Profile </h4>
//           <p
//             className="text-center mb-0"
//             style={{ fontSize: "14px", color: "#818A97", whiteSpace: "normal" }}
//           >
//             This information is shared exclusively with
//             <br />
//             Thinktrek and will not be visible on your profile.
//           </p>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="p-0">
//         <div className="d-flex flex-row flex-wrap  pt-1">
//           <button
//             style={{
//               background: "#F2641F",
//               width: 40,
//               height: 40,
//               borderRadius: 50,
//               border: "none",
//               margin: "0 5px",
//               marginBottom: "10px",
//               padding: 0,
//             }}
//           >
//             {!preview ? (
//               <IoCamera
//                 onClick={() => {
//                   NiceModal.show(Camera_Modal, {
//                     full_name,

//                     file,
//                     preview,
//                   });
//                   modal.hide();
//                 }}
//                 style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
//               />
//             ) : (
//               <img
//                 style={{ borderRadius: 35, width: 40, height: 40 }}
//                 onClick={() => {
//                   NiceModal.show(Camera_Modal, {
//                     full_name,
//                     file,
//                     preview,
//                   });
//                   modal.hide();
//                 }}
//                 src={preview}
//                 width="100%"
//                 height="100%"
//               />
//             )}
//           </button>
//           <div className="position-relative d-flex">
//             <Uploader
//               onChange={(event) => setFileList(event[0].blobFile)}
//               fileListVisible={false}
//               listType="picture"
//               action="/"
//               onUpload={(file) => {
//                 setUploading(true);
//                 previewFile(file.blobFile, (value) => {
//                   setFileInfo(value);
//                   //console.log(value);
//                 });
//               }}
//             >
//               <button
//                 style={{
//                   background: "#F2641F",
//                   width: 40,
//                   height: 40,
//                   borderRadius: 50,
//                   border: "none",
//                   margin: "0 5px",
//                   marginBottom: "5px",
//                   marginBottom: "10px",
//                 }}
//               >
//                 {fileInfo ? (
//                   <img src={fileInfo} width="100%" height="100%" />
//                 ) : (
//                   <ImageIcon style={{ fontSize: 15, color: "#fff" }} />
//                 )}
//               </button>
//             </Uploader>
//             <span className="fromGallery">
//               <ImageIcon style={{ fontSize: 10, color: "#fff" }} />
//             </span>
//           </div>

//           {Avatar_data.map((item, key) => {
//             return (
//               <AvatarGroup spacing={6} key={key} className="position-relative">
//                 <Avatar
//                   color="green"
//                   bordered={selectedAvatar === item.avatar_id ? true : false}
//                   key={key}
//                   onClick={() => {
//                     handleAvatarClick(item.avatar_id);
//                   }}
//                   style={{
//                     cursor: "pointer",
//                     margin: "0 5px",
//                     marginBottom: "10px",
//                   }}
//                   circle
//                   src={require(`../assets/avatars/${item.avatar_icon}`)}
//                   alt=""
//                 />
//                 {selectedAvatar === item.avatar_id && (
//                   <div
//                     className="d-flex align-items-center justify-content-center"
//                     style={{
//                       bottom: "11px",
//                       right: "0",
//                       width: "13px",
//                       height: "13px",
//                       borderRadius: "3px",
//                       background: "#2ED573",
//                       position: "absolute",
//                     }}
//                   >
//                     <TiTick
//                       style={{ fontSize: "10px" }}
//                       className="text-white"
//                     />
//                   </div>
//                 )}
//               </AvatarGroup>
//             );
//           })}
//         </div>
//         <div className="editField" style={{ marginTop: 10 }}>
//           <label className="text-white">Username*</label>
//           <Input
//             disabled={name_status ? true : false}
//             value={name}
//             placeholder="Fill username"
//             onChange={handleUsername}
//             style={{ background: "none" }}
//           />
//           {err && (
//             <span style={{ fontSize: "10px", color: "red" }}>
//               Enter username
//             </span>
//           )}
//           <span
//             className="editLink d-flex align-items-center"
//             onClick={() => setName_status(false)}
//           >
//             Edit
//           </span>
//         </div>
//         <div className="editField" style={{ marginTop: 10 }}>
//           <label className="text-white d-block">
//             Date of birth
//             <Whisper
//               placement="top"
//               controlId="control-id-click"
//               trigger="click"
//               speaker={tooltip}
//             >
//               <img
//                 className="ms-2"
//                 src={require("../assets/info-icon.png")}
//                 width={15}
//                 alt=""
//               />
//             </Whisper>
//           </label>
//           <DatePicker
//             placeholder={"Not specified"}
//             disabled={dob_status ? true : false}
//             oneTap
//             placement="top"
//             className="w-100"
//             onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
//           />
//           <span
//             className="editLink d-flex align-items-center"
//             onClick={() => setDob_status(false)}
//           >
//             Edit
//           </span>
//         </div>
//         <div className="editField" style={{ marginTop: 10 }}>
//           <label className="text-white">Gender</label>
//           <select
//             className="w-100"
//             id="gender"
//             name="gender"
//             value={selectedGender}
//             onChange={handleGenderChange}
//           >
//             <option value="">Select Gender</option> {/* Default empty option */}
//             {genders.map((gender, index) => (
//               <option key={index} value={gender}>
//                 {gender}
//               </option>
//             ))}
//           </select>
//           <span
//             className="editLink d-flex align-items-center"
//             style={{ pointerEvents: "none" }}
//           >
//             <img src={require("../assets/down-arrow.png")} width={15} alt="" />
//           </span>
//         </div>
//         <div className="text-center">
//           {!file &&
//           !selectedAvatar &&
//           !selectedDate &&
//           !selectedMonth &&
//           !selectedYear &&
//           !selectedGender &&
//           !fileList ? (
//             <Button
//               onClick={() => {
//                 onContactFromSubmit();
//                 // modal.hide();
//               }}
//               className="guest-btn w-auto"
//               style={{ borderRadius: 20, padding: "10px 25px" }}
//               block
//             >
//               Skip for Now
//             </Button>
//           ) : (
//             <Button
//               className="w-auto"
//               onClick={onContactFromSubmit}
//               loading={loading}
//               appearance="primary"
//               style={{
//                 borderRadius: 20,
//                 background: "#F2641F",
//                 padding: "10px 25px",
//               }}
//               block
//             >
//               Save & Continue
//             </Button>
//           )}
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// });

// const Phone_Modal = NiceModal.create(({ id, name }) => {
//   const modal = useModal();
//   let cookies = new Cookies();
//   const [phone, setPhone] = useState("");
//   const [dial, setDial] = useState("");
//   const [show, setShow] = useState(false);
//   const [code, setCode] = useState("");
//   const [loading, setLoading] = useState(false);
//   let validate_users = cookies.get("token");
//   const { profile_status_load, add_profile_load, add_guest_token } = useStore();
//   let IP_URL = process.env.REACT_APP_IP_URL;
//   let [offline_data, setOffline_data] = useState({
//     ip: "122.176.24.171",
//     network: "122.176.24.0/24",
//     version: "IPv4",
//     city: "Kolkata",
//     region: "West Bengal",
//     region_code: "WB",
//     country: "IN",
//     country_name: "India",
//     country_code: "IN",
//     country_code_iso3: "IND",
//     country_capital: "New Delhi",
//     country_tld: ".in",
//     continent_code: "AS",
//     in_eu: false,
//     postal: "700054",
//     latitude: 22.518,
//     longitude: 88.3832,
//     timezone: "Asia/Kolkata",
//     utc_offset: "+0530",
//     country_calling_code: "+91",
//     currency: "INR",
//     currency_name: "Rupee",
//     languages:
//       "en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc",
//     country_area: 3287590,
//     country_population: 1352617328,
//     asn: "AS24560",
//     org: "Bharti Airtel Ltd., Telemedia Services",
//   });

//   const guest_user = () => {
//     if (!validate_users) {
//       fetch_user_details();
//     }
//   };
//   const fetch_user_details = () => {
//     axios
//       .get(IP_URL)
//       .then((res) => {
//         console.log({ res });
//         cookies.set("network_details", res.data, { path: "/" });
//         let data = res.data;
//         validate_user(data);
//       })
//       .catch((e) => {
//         console.log(e.config.data);
//         if (e.config.data === undefined) {
//           validate_user(offline_data);
//         }
//       });
//   };

//   const validate_user = (data) => {
//     let params = {
//       ip_address: data.ip,
//       country: data.country_name,
//       city: data.city,
//       latitude: data.latitude,
//       longitude: data.longitude,
//       additional_details: JSON.stringify(data),
//     };
//     Service.login_register_visitor(params)
//       .then((res) => {
//         // console.log(res);
//         logEvent(analytics, "guest_user_count", {
//           country: data?.country_name,
//           city: data?.city,
//         });
//         cookies.set("token", res.token, { path: "/" });
//         add_guest_token(res.token);
//       })
//       .catch((e) => console.log(e.message));
//   };
//   const fetch_user_network = () => {
//     axios
//       .get(IP_URL)
//       .then((res) => {
//         cookies.set("network_details", res.data, { path: "/" });
//       })
//       .catch((e) => {
//         // console.log(e.config.data)
//         if (e.config.data === undefined) {
//           // validate_user(offline_data)
//         }
//       });
//   };
//   const handleInputChange = (value, data) => {
//     //const inputValue = value
//     // console.log(value)
//     let code = `+${data.dialCode}`;
//     setDial(code);
//     let number = value.toString().slice(data.dialCode.length);
//     // console.log(number?.length)
//     //  console.log(value.toString().slice(data.dialCode.length))
//     setPhone(number);
//     //console.log(value.split(' ')[1]);
//   };
//   let network = cookies.get("network_details");
//   const onContinue = async () => {
//     setLoading(true);
//     let params = {
//       phone_number: phone,
//     };
//     await Service.login_number(params)
//       .then((res) => {
//         // console.log(res.otp)
//         if (res.status == 0) {
//           setLoading(false);
//         }
//         if (res.status == 1) {
//           if (!network) {
//             fetch_user_network();
//           }
//           setLoading(false);
//           modal.hide();
//           NiceModal.show(Otp_Modal, {
//             number: phone,
//             otp_code: res.otp,
//             dial_code: dial,
//             expire: res?.otp_expires,
//           });
//         }
//       })
//       .catch((e) => console.log(e.message));
//   };
//   return (
//     <Modal
//       className="rs-modal-xs"
//       open={modal.visible}
//       onClose={modal.hide}
//       onExited={modal.remove}
//       backdrop="static"
//     >
//       <Modal.Header>
//         <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//           <h4 className="mb-4 fw-normal">Log In</h4>
//           <p className="mb-0" style={{ fontSize: "16px" }}>
//             Welcome to the game portal
//           </p>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="text-center">
//         <PhoneInput
//           className="phone"
//           copyNumbersOnly={true}
//           //autoFormat={false}
//           prefix="+"
//           countryCodeEditable={false}
//           placeholder="Fill mobile number"
//           country={"in"}
//           //  value={phone}
//           onChange={(value, data, event, formattedValue) =>
//             handleInputChange(value, data)
//           }
//         />
//         <Button
//           className="my-4"
//           loading={loading}
//           onClick={() => {
//             onContinue();
//           }}
//           disabled={!phone ? true : false}
//           appearance="primary"
//           style={{
//             width: "auto",
//             marginTop: 10,
//             borderRadius: 20,
//             background: !phone ? "#818A97" : "#F2641F",
//             padding: "10px 15px",
//           }}
//         >
//           Play Now
//         </Button>
//         <p
//           className="text-secondary"
//           style={{ justifyItems: "flex-end", flex: 1 }}
//         >
//           OR
//         </p>
//         <div style={{ marginTop: 10 }}></div>
//         <Button
//           className="guest-btn"
//           onClick={() => {
//             guest_user();
//             modal.hide();
//           }}
//           // appearance="ghost"
//           style={{ marginTop: 10, borderRadius: 20, width: "auto" }}
//           block
//         >
//           Continue as Guest
//         </Button>
//         <hr />
//         <p
//           className="text-white text-start m-0"
//           style={{ fontSize: "12px", color: "#b5b5b5 !important" }}
//         >
//           Note: As a guest you are agreeing to the terms that you are 13+ years
//           of age. To get the advantages and special privilages of partcipating
//           in the Weekly Leaderboard Challenges, you need to Login.
//         </p>
//       </Modal.Body>
//     </Modal>
//   );
// });

// const Otp_Modal = NiceModal.create(
//   ({ number, otp_code, dial_code, expire }) => {
//     const modal = useModal();
//     let cookies = new Cookies();
//     const [phone, setPhone] = useState("");
//     const [dial, setDial] = useState("");
//     const [show, setShow] = useState(false);
//     const [code, setCode] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [otp, setOtp] = useState("");
//     let [msg, setMsg] = useState("");
//     const [expire_time, setExpire_time] = useState("");
//     const { profile_status_load, add_profile_load } = useStore();
//     let network = cookies.get("network_details");

//     const verifyCode = async () => {
//       setLoading(true);
//       let params = {
//         phone_number: number,
//         otp: otp,
//         ipAddress: network?.ip,
//         country: network?.country_name,
//         country_code: dial_code,
//         city: network?.city,
//         latitude: network?.latitude,
//         longitude: network?.longitude,
//       };
//       await Service.verify_otp(params)
//         .then((res) => {
//           //console.log(res.data.visitorDetails.full_name)
//           if (res.status == 0) {
//             setMsg(res?.message);
//             setLoading(false);
//           }
//           if (res.status == 1) {
//             if (res?.data?.profile_completion_status == false) {
//               logEvent(analytics, "registered_user_count", {
//                 country: network?.country_name,
//                 city: network?.city,
//               });
//               cookies.set("login_token", res.token, { path: "/" });
//               modal.hide();
//               NiceModal.show(Profile_Modal, {
//                 full_name: res?.data?.visitorDetails?.full_name,
//               });
//               setLoading(false);
//             }
//             add_profile_load(true);
//             cookies.set("login_token", res.token, { path: "/" });
//             modal.hide();
//             setMsg("");
//             ///window.location.reload();
//             //NiceModal.show(Profile_Modal, { id: 'game_id', })
//             setLoading(false);
//           }
//         })
//         .catch((e) => console.log(e.message));
//     };

//     const onContinue = async () => {
//       // setLoading(true);
//       let params = {
//         phone_number: number,
//       };
//       await Service.login_number(params)
//         .then((res) => {
//           //console.log(res);
//           if (res.status == 0) {
//             //setLoading(false);
//           }
//           if (res.status == 1) {
//             setCode(res?.otp);
//             setExpire_time(res?.otp_expires);
//           }
//         })
//         .catch((e) => console.log(e.message));
//     };

//     const Resend = () => (
//       <div
//         className="text-end text-capitalize"
//         style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
//       >
//         <span
//           style={{ cursor: "pointer" }}
//           onClick={onContinue}
//           className="text-white"
//         >
//           resend
//         </span>
//       </div>
//     );

//     // Renderer callback with condition
//     const renderer = ({ hours, minutes, seconds, completed }) => {
//       if (completed) {
//         // Render a completed state
//         return <Resend />;
//       } else {
//         // Render a countdown
//         return (
//           <div
//             className="text-start"
//             style={{ width: "240px", margin: "0 auto", fontSize: "14px" }}
//           >
//             <span className="text-white">
//               Expire in :{" "}
//               <span style={{ color: "#F2641F" }}>
//                 {minutes}:{seconds}
//               </span>
//             </span>
//           </div>
//         );
//       }
//     };

//     return (
//       <Modal
//         className="rs-modal-xs"
//         open={modal.visible}
//         onClose={modal.hide}
//         onExited={modal.remove}
//         backdrop="static"
//       >
//         <Modal.Header>
//           <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//             <h4 className="mb-4 fw-normal">Verify</h4>
//             <p
//               className="mb-0"
//               style={{ fontSize: "16px", whiteSpace: "normal" }}
//             >
//               {`Please enter your verification code sent to ${dial_code}${number}`}
//             </p>
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="text-center">
//           <p style={{ color: "red" }}>{msg}</p>
//           <OtpInput
//             inputType="tel"
//             value={otp}
//             onChange={setOtp}
//             numInputs={5}
//             containerStyle={{
//               margin: "5px",
//               justifyContent: "center",
//             }}
//             inputStyle={{
//               height: "40px",
//               width: "40px",
//               margin: "5px",
//               background: "#333947",
//               color: "#F5F9FF",
//               borderRadius: 5,
//               border: "none",
//             }}
//             // renderSeparator={<span>-</span>}
//             renderInput={(props) => <input {...props} />}
//           />
//           <Countdown
//             autoStart={true}
//             date={moment(expire_time || expire)}
//             renderer={renderer}
//           />
//           <p className="text-white m-0">{code || otp_code}</p>
//           <Button
//             disabled={otp.length == 5 ? false : true}
//             className="text-white"
//             loading={loading}
//             onClick={() => {
//               verifyCode();
//               // modal.hide()
//               // NiceModal.show(Profile_Modal)
//             }}
//             //#818A97
//             // appearance="primary"
//             style={{
//               marginTop: 25,
//               borderRadius: 20,
//               padding: "10px 40px",
//               background: otp.length == 5 ? "#F2641F" : "#818A97",
//               color: otp.length == 5 ? "#fff" : "#ffffff47",
//             }}
//           >
//             Verify
//           </Button>
//         </Modal.Body>
//       </Modal>
//     );
//   }
// );

// const Profile_edit_Modal = NiceModal.create(({ navigate }) => {
//   const modal = useModal();
//   let cookies = new Cookies();
//   let ACCESS_TOKEN_USER = cookies.get("login_token");
//   let network_details = cookies.get("network_details");
//   const toaster = useToaster();
//   const [uploading, setUploading] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [fileInfo, setFileInfo] = useState(null);
//   const [fileList, setFileList] = useState(null);
//   const [err, setErr] = useState(false);
//   const [name, setName] = useState("");
//   const [gender, setGender] = useState("");
//   const [dob, setDob] = useState("");
//   const [phone, setPhone] = useState("");
//   const [country_code, setCountry_code] = useState("");
//   const [country, setCountry] = useState("");
//   const [score, setScore] = useState("");
//   const [percent, setPercent] = useState(0);
//   const [avatar_id, setAvatar_id] = useState(0);
//   const [date_of_birth, setDate_of_birth] = useState("");
//   const [fav_list, setFav_list] = useState([]);
//   const [play_history, setPlay_history] = useState([]);
//   const [country_alias, setCountry_alias] = useState("");
//   function previewFile(file, callback) {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(file);
//   }

//   useEffect(() => {
//     visitor_details();
//     fav_games();
//   }, []);

//   const log_out = () => {
//     cookies.remove("token", { path: "/" });
//     cookies.remove("network_details", { path: "/" });
//     cookies.remove("login_token", { path: "/" });
//     navigate("/");
//     window.location.reload(true);
//   };

//   const visitor_details = () => {
//     Service.visitor_details()
//       .then((res) => {
//         let date = res?.data?.created_at;
//         let date_of_birth = res?.data?.date_of_birth;

//         if (res.status == 1) {
//           setName(res?.data?.full_name);
//           setDob(date);
//           setDate_of_birth(date_of_birth);
//           setGender(res?.data?.gender);
//           setCountry(res?.data?.country);
//           setScore(res?.data?.equivalent_point);
//           setFileInfo(res?.data?.profile_picture);
//           setPercent(res?.data?.profile_completion_percentage);
//           setAvatar_id(res?.data?.avatar_id);
//           setPlay_history(res?.data?.playing_history);
//           setPhone(res?.data?.phone_number);
//           setCountry_code(res?.data?.country_code);
//           setCountry_alias(res?.data?.country_code_alias);
//         }
//       })
//       .catch((e) => console.log(e));
//   };

//   const onContactFromSubmit = async () => {
//     setLoading(true);
//     if (!name) {
//       setErr(true);
//       setLoading(false);
//       return;
//     }
//     setErr(false);
//     let form = new FormData();
//     form.append("full_name", name);
//     form.append("profile_picture", fileList);
//     form.append("date_of_birth", moment(dob).format("YYYY-MM-DD"));
//     form.append("gender", gender);
//     try {
//       await fetch(
//         `${process.env.REACT_APP_BASE_URL_V2}/api/edit-visitor-profile`,
//         {
//           method: "POST",
//           body: form,
//           headers: {
//             //  'Content-Type': 'multipart/form-data',
//             Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
//           },
//         }
//       )
//         .then((res) => res.json())
//         .then((data) => {
//           if (data.status == 0) {
//             setLoading(false);
//           }
//           if (data.status == 1) {
//             setLoading(false);
//             modal.hide();
//           }
//         })
//         .catch((e) => e.message);
//     } catch (e) {
//       throw e;
//     }
//   };

//   const fav_games = () => {
//     Service.fav_games()
//       .then((res) => {
//         setFav_list(res?.data);
//       })
//       .catch((e) => console.log);
//   };

//   const fav_status = (id, status) => {
//     let params = {
//       game_id: id,
//       status: status,
//     };
//     Service.fav_games_status(params)
//       .then((res) => {
//         //console.log(res);
//         if (res.status == 1) {
//           fav_games();
//         }
//       })
//       .catch((e) => console.log(e));
//   };

//   const targetDate = new Date(dob);
//   const currentDate = new Date();
//   const timeDifference = currentDate - targetDate;
//   const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
//   const filteredAvatars = Avatar_data?.filter(
//     (avatar) => avatar?.avatar_id === avatar_id
//   );

//   return (
//     <>
//       <Modal
//         onBackdropClick={() => modal.hide()}
//         // dialogStyle={{
//         //   width: "100%",
//         //   // marginLeft: "-50%",
//         //   height: "100%",
//         //   marginTop: "5%",
//         // }}
//         open={modal.visible}
//         onClose={modal.hide}
//         onExited={modal.remove}
//         className="profileModal"
//       >
//         {/* <Modal.Header>
//           <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//             Profile{" "}
//           </Modal.Title>
//         </Modal.Header> */}

//         <Modal.Body className="p-0 mh-100">
//           <div className="row gx-2">
//             <div className="col-12 col-sm-3 mb-3">
//               <div className="dark-bg">
//                 <Modal.Title className="text-white text-center mb-4">
//                   Profile{" "}
//                 </Modal.Title>
//                 <div className="text-center">
//                   {
//                     <div className="position-relative profileAvatar">
//                       <Avatar
//                         alt=""
//                         size="xl"
//                         circle
//                         src={
//                           fileInfo ||
//                           (filteredAvatars.length > 0 &&
//                             require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
//                         }
//                       />
//                       <div className="editIcon d-flex">
//                         <img
//                           src={require("../assets/edit.png")}
//                           alt=""
//                           style={{ cursor: "pointer" }}
//                           onClick={() => {
//                             modal.hide();
//                             NiceModal.show(Update_Profile_Modal, {
//                               avatar_id: avatar_id,
//                               gender_update: gender,
//                               date_of_birth: date_of_birth,
//                               full_name: name,
//                               profile_picture: fileInfo,
//                             });
//                           }}
//                         />
//                       </div>
//                     </div>
//                   }
//                 </div>

//                 <div className="profileProgress mt-2">
//                   <p
//                     style={{ fontSize: "12px" }}
//                     className="text-white m-0 d-flex justify-content-between align-items-center"
//                   >
//                     {`Your Profile is ${percent}% complete`}
//                     <span
//                       onClick={() => {
//                         // if (percent !== 100) {
//                         modal.hide();
//                         NiceModal.show(Update_Profile_Modal, {
//                           avatar_id: avatar_id,
//                           gender_update: gender,
//                           date_of_birth: date_of_birth,
//                           full_name: name,
//                           profile_picture: fileInfo,
//                         });
//                         //}
//                       }}
//                       style={{
//                         color: "#F2641F",
//                         cursor: "pointer",
//                       }}
//                     >
//                       {percent == 100 ? `edit profile` : `complete now`}
//                       <img
//                         className="ms-1"
//                         src={require("../assets/right-arrow.png")}
//                         style={{ width: "5px" }}
//                         alt=""
//                       />
//                     </span>
//                   </p>
//                   <Progress.Line
//                     className="text-white p-0 mt-2"
//                     percent={percent}
//                     status={percent == 100 && "success"}
//                     strokeColor={percent == 100 ? "green" : "#F2641F"}
//                     strokeWidth={5}
//                     trailColor={"#212530"}
//                     showInfo={false}
//                   />
//                 </div>

//                 <div className="profileDetails">
//                   <div>
//                     <p className="detailTitle">Your Score</p>
//                     <p className="text-white d-flex align-items-center">
//                       <img
//                         className="me-1"
//                         src={require("../assets/star.png")}
//                         style={{ width: "15px" }}
//                         alt=""
//                       />
//                       {score}
//                     </p>
//                   </div>
//                   <div>
//                     <p className="detailTitle">User Name</p>
//                     <p className="text-white">{name}</p>
//                   </div>
//                   <div>
//                     <p className="detailTitle">Country</p>
//                     <p className="text-white">
//                       {country}
//                       <Flag
//                         height={"20px"}
//                         width={"20px"}
//                         className="ms-2"
//                         code={country_alias}
//                       />
//                     </p>
//                   </div>
//                   <div>
//                     <p className="detailTitle">Phone Number</p>
//                     <p className="text-white">{`${country_code} ${phone}`}</p>
//                   </div>
//                   <div>
//                     <p className="detailTitle">Member Since</p>
//                     <p className="text-white">
//                       {daysDifference == 0 ? "Today" : daysDifference}{" "}
//                       <span>
//                         {daysDifference == 0
//                           ? ""
//                           : daysDifference > 1
//                           ? "Days"
//                           : "Day"}
//                       </span>
//                     </p>
//                   </div>
//                 </div>
//                 <div className="text-center">
//                   <Button
//                     onClick={() => {
//                       navigate("/playing_history", {
//                         state: {
//                           history: play_history,
//                         },
//                       });
//                       modal.hide();
//                     }}
//                     style={{
//                       borderRadius: 20,
//                     }}
//                     className="guest-btn me-3"
//                   >
//                     Game History
//                   </Button>
//                   <Button
//                     onClick={log_out}
//                     appearance="primary"
//                     style={{
//                       borderRadius: 20,
//                       background: "#F2641F",
//                       padding: "10px 25px",
//                     }}
//                   >
//                     Logout
//                   </Button>
//                 </div>
//               </div>
//             </div>
//             <div className="col-12 col-sm-9">
//               <div className="dark-bg h-100 p-3">
//                 <Modal.Title className="text-white">
//                   My Favourite Games
//                 </Modal.Title>
//                 <div className="row gx-2 mt-3 scrollBox">
//                   {fav_list.map((item, index) => {
//                     return (
//                       <>
//                         <div className="col-6 col-md-3 position-relative">
//                           <div className="gameItem">
//                             <HoverVideoPlayer
//                               onClick={() => {
//                                 modal.hide();
//                                 navigate(`/game/${item?.id}/${item?.title}`, {
//                                   state: {
//                                     datas: item,
//                                     flag: "home",
//                                   },
//                                 });
//                               }}
//                               style={{ cursor: "pointer" }}
//                               className="connect_the_ball_ek11 gameThumb"
//                               restartOnPaused
//                               overlayTransitionDuration={250}
//                               videoSrc={item?.video_url || item?.video_upload}
//                               pausedOverlay={
//                                 <img
//                                   src={item?.cover_picture}
//                                   alt=""
//                                   className="connect_the_ball_ek11 gameThumb"
//                                 />
//                               }
//                               loadingOverlay={
//                                 <div className="loading-overlay">
//                                   <div className="loading-spinner" />
//                                 </div>
//                               }
//                             />

//                             <div className="hoverText">{item?.title}</div>
//                             <div className="deleteGame">
//                               <img
//                                 onClick={async () => {
//                                   const options = {
//                                     okButtonText: "Yes",
//                                     cancelButtonText: "Cancel",
//                                   };
//                                   const result = await confirm(
//                                     "Are you sure? You want to remove this game from your favourite games list",
//                                     options
//                                   );

//                                   if (result) {
//                                     fav_status(item?.id, 0);
//                                   }
//                                 }}
//                                 src={require("../assets/close.png")}
//                                 style={{ width: 15 }}
//                                 alt=""
//                               />
//                             </div>
//                           </div>
//                           {item.tag == "new" && (
//                             <div className="new-badge">New</div>
//                           )}
//                           {item.last_updated && (
//                             <div className="update-badge">Updated</div>
//                           )}
//                         </div>
//                       </>
//                     );
//                   })}
//                 </div>
//                 {fav_list.length == 0 && (
//                   <div className="row">
//                     <div className="col">
//                       <h5 className="text-center text-white">
//                         Sorry! There is no games in your Favourites List. Start
//                         playing.
//                       </h5>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// });

// const Update_Profile_Modal = NiceModal.create(
//   ({
//     avatar_id,
//     full_name,
//     gender_update,
//     date_of_birth,
//     profile_picture,
//     file,
//     preview,
//   }) => {
//     const modal = useModal();
//     let cookies = new Cookies();
//     let ACCESS_TOKEN_USER = cookies.get("login_token");
//     const toaster = useToaster();
//     const [uploading, setUploading] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [fileInfo, setFileInfo] = useState(profile_picture);
//     const [fileList, setFileList] = useState(null);
//     const [err, setErr] = useState(false);
//     const [name, setName] = useState(full_name);
//     const [gender, setGender] = useState(gender_update);
//     const [dob, setDob] = useState(date_of_birth);
//     const [phone, setPhone] = useState("");
//     const [selectedAvatar, setSelectedAvatar] = useState(avatar_id);
//     const [dob_status, setDob_status] = useState(true);
//     const [name_status, setName_status] = useState(true);
//     const tooltip = (
//       <Tooltip>
//         If DOB is not declared then you are agreeing to the terms that you are
//         13+ years of age. To get the advantages and special privilages of
//         partcipating in the Weekly Leaderboard Challenges, you need to Login.
//       </Tooltip>
//     );
//     //const previewUrl = URL.createObjectURL(file);
//     // console.log(previewUrl);
//     // Function to handle avatar selection
//     const handleAvatarClick = (index) => {
//       console.log({ index });
//       setSelectedAvatar(index);
//     };
//     function previewFile(file, callback) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         callback(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//     const onChange = (date, dateString) => {
//       console.log(date, dateString);
//     };
//     const onContactFromSubmit = async () => {
//       setLoading(true);
//       if (!name) {
//         setErr(true);
//         setLoading(false);
//         return;
//       }
//       setErr(false);
//       let date_of_birth = `${selectedYear}-${selectedMonth}-${selectedDate}`;
//       let form = new FormData();
//       form.append("full_name", name);
//       form.append("profile_picture", file || fileList);
//       form.append("date_of_birth", moment(dob).format("YYYY-MM-DD") || "");
//       form.append("gender", selectedGender || "");
//       form.append("avatar_id", selectedAvatar || "");
//       try {
//         await fetch(`${BASE_URL}/api/edit-visitor-profile`, {
//           method: "POST",
//           body: form,
//           headers: {
//             //  'Content-Type': 'multipart/form-data',
//             Authorization: `Bearer ${ACCESS_TOKEN_USER}`,
//           },
//         })
//           .then((res) => res.json())
//           .then((data) => {
//             //console.log("Success thread Contact===========>:", data);
//             if (data.status == 0) {
//               setLoading(false);
//             }
//             if (data.status == 1) {
//               setLoading(false);
//               //    console.log(
//               //   "Success thread Contact===========>:",
//               //   data
//               // );
//               modal.hide();
//               window.location.reload();
//             }
//           })
//           .catch((e) => e.message);
//       } catch (e) {
//         throw e;
//       }
//     };

//     const handleUsername = (event) => {
//       setName(event);
//     };

//     const generateRandomUsername = () => {
//       const adjectives = [
//         "Swift",
//         "Mighty",
//         "Epic",
//         "Mystic",
//         "Viking",
//         "Fearless",
//         "Shadow",
//         "Blazing",
//       ];
//       const nouns = [
//         "Warrior",
//         "Knight",
//         "Dragon",
//         "Hunter",
//         "Champion",
//         "Ninja",
//         "Phoenix",
//         "Titan",
//       ];
//       const randomAdjective =
//         adjectives[Math.floor(Math.random() * adjectives.length)];
//       const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
//       const randomNumber = Math.floor(Math.random() * 10000); // Optional: Add a random number at the end
//       return `${randomAdjective}${randomNoun}${randomNumber}`;
//     };

//     useEffect(() => {
//       setName(full_name);
//     }, []);

//     const genders = [
//       "Male",
//       "Female",
//       "Non-Binary",
//       "Other",
//       "Prefer not to say",
//     ];

//     // State to track the selected gender
//     const [selectedGender, setSelectedGender] = useState(gender_update);

//     // Handle change in selected gender
//     const handleGenderChange = (event) => {
//       setSelectedGender(event.target.value);
//     };

//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = 1960; year <= currentYear; year++) {
//       years.push(year);
//     }
//     const [selectedYear, setSelectedYear] = useState("");
//     const [selectedMonth, setSelectedMonth] = useState("");
//     const [selectedDate, setSelectedDate] = useState("");

//     const handleDateChange = (event) => {
//       setSelectedDate(event.target.value);
//     };
//     const handleMonthChange = (event) => {
//       setSelectedMonth(event.target.value);
//     };
//     const handleYearChange = (event) => {
//       console.log(event.target.value);
//       setSelectedYear(event.target.value);
//     };

//     const days = Array.from({ length: 31 }, (_, index) => index + 1);

//     const months = [
//       "January",
//       "February",
//       "March",
//       "April",
//       "May",
//       "June",
//       "July",
//       "August",
//       "September",
//       "October",
//       "November",
//       "December",
//     ];

//     return (
//       <Modal
//         className="rs-modal-xs"
//         open={modal.visible}
//         onClose={() => modal.hide()}
//         onExited={() => modal.remove()}
//       >
//         <Modal.Header>
//           <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//             <h4 className="mb-3 fw-normal">Update Profile </h4>
//             <p
//               className="text-center mb-0"
//               style={{
//                 fontSize: "14px",
//                 color: "#818A97",
//                 whiteSpace: "normal",
//               }}
//             >
//               This information is shared exclusively with
//               <br />
//               Thinktrek and will not be visible on your profile.
//             </p>
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="p-0">
//           <div className="d-flex flex-row flex-wrap pt-1">
//             <button
//               style={{
//                 background: "#F2641F",
//                 width: 40,
//                 height: 40,
//                 borderRadius: 50,
//                 border: "none",
//                 margin: "0 5px",
//                 marginBottom: "10px",
//                 padding: 0,
//               }}
//             >
//               {!preview ? (
//                 <IoCamera
//                   onClick={() => {
//                     NiceModal.show(Camera_Modal, {
//                       avatar_id,
//                       full_name,
//                       gender_update,
//                       date_of_birth,
//                       profile_picture,
//                       file,
//                     });
//                     modal.hide();
//                   }}
//                   style={{ color: "#fff", fontSize: "18px", marginBottom: 4 }}
//                 />
//               ) : (
//                 <img
//                   style={{ borderRadius: 35, width: 40, height: 40 }}
//                   onClick={() => {
//                     NiceModal.show(Camera_Modal, {
//                       avatar_id,
//                       full_name,
//                       gender_update,
//                       date_of_birth,
//                       profile_picture,
//                       file,
//                     });
//                     modal.hide();
//                   }}
//                   src={preview}
//                   width="100%"
//                   height="100%"
//                 />
//               )}
//             </button>
//             <div className="position-relative d-flex">
//               <Uploader
//                 onChange={(event) => setFileList(event[0].blobFile)}
//                 fileListVisible={false}
//                 listType="picture"
//                 action="/"
//                 onUpload={(file) => {
//                   setUploading(true);
//                   previewFile(file.blobFile, (value) => {
//                     setFileInfo(value);
//                     //console.log(value);
//                   });
//                 }}
//               >
//                 <button
//                   style={{
//                     background: "#F2641F",
//                     width: 40,
//                     height: 40,
//                     borderRadius: 50,
//                     border: "none",
//                     margin: "0 5px",
//                     marginBottom: "10px",
//                   }}
//                 >
//                   {fileInfo ? (
//                     <img src={fileInfo} width="100%" height="100%" />
//                   ) : (
//                     <ImageIcon style={{ fontSize: 15, color: "#fff" }} />
//                   )}
//                 </button>
//               </Uploader>
//               <span className="fromGallery">
//                 <ImageIcon style={{ fontSize: 10, color: "#fff" }} />
//               </span>
//             </div>
//             {Avatar_data.map((item, key) => {
//               return (
//                 <AvatarGroup
//                   spacing={6}
//                   key={key}
//                   className="position-relative"
//                 >
//                   <Avatar
//                     color="green"
//                     bordered={selectedAvatar === item.avatar_id ? true : false}
//                     key={key}
//                     onClick={() => {
//                       handleAvatarClick(item.avatar_id);
//                     }}
//                     style={{
//                       cursor: "pointer",
//                       margin: "0 5px",
//                       marginBottom: "10px",
//                     }}
//                     circle
//                     src={require(`../assets/avatars/${item.avatar_icon}`)}
//                     alt=""
//                   />
//                   {selectedAvatar === item.avatar_id && (
//                     <div
//                       className="d-flex align-items-center justify-content-center"
//                       style={{
//                         bottom: "11px",
//                         right: "0",
//                         width: "13px",
//                         height: "13px",
//                         borderRadius: "3px",
//                         background: "#2ED573",
//                         position: "absolute",
//                       }}
//                     >
//                       <TiTick
//                         style={{ fontSize: "10px" }}
//                         className="text-white"
//                       />
//                     </div>
//                   )}
//                 </AvatarGroup>
//               );
//             })}
//           </div>
//           <div className="editField" style={{ marginTop: 10 }}>
//             <label>Username*</label>
//             <Input
//               disabled={name_status ? true : false}
//               value={name}
//               placeholder="Fill username"
//               onChange={handleUsername}
//               style={{ background: "none" }}
//             />
//             {err && (
//               <span style={{ fontSize: "10px", color: "red" }}>
//                 Enter username
//               </span>
//             )}
//             <span
//               className="editLink d-flex align-items-center"
//               onClick={() => setName_status(false)}
//             >
//               Edit
//             </span>
//           </div>
//           <div className="editField" style={{ marginTop: 10 }}>
//             <label className="d-block">
//               Date of birth
//               <Whisper
//                 placement="top"
//                 controlId="control-id-click"
//                 trigger="click"
//                 speaker={tooltip}
//               >
//                 <img
//                   className="ms-2"
//                   src={require("../assets/info-icon.png")}
//                   width={15}
//                   alt=""
//                 />
//               </Whisper>
//             </label>
//             <DatePicker
//               placeholder="Not specified"
//               disabled={dob_status ? true : false}
//               oneTap
//               placement="top"
//               className="w-100"
//               defaultValue={date_of_birth ? new Date(date_of_birth) : null}
//               onChange={(e) => setDob(moment(e).format("YYYY-MM-DD"))}
//             />
//             <span
//               className="editLink d-flex align-items-center"
//               onClick={() => setDob_status(false)}
//             >
//               Edit
//             </span>
//           </div>
//           <div className="editField" style={{ marginTop: 10 }}>
//             <label className="d-block">Gender</label>
//             <select
//               className="w-100"
//               id="gender"
//               name="gender"
//               value={selectedGender}
//               onChange={handleGenderChange}
//             >
//               <option value="">Select Gender</option>{" "}
//               {/* Default empty option */}
//               {genders.map((gender, index) => (
//                 <option key={index} value={gender}>
//                   {gender}
//                 </option>
//               ))}
//             </select>
//             <span
//               className="editLink d-flex align-items-center"
//               style={{ pointerEvents: "none" }}
//             >
//               <img
//                 src={require("../assets/down-arrow.png")}
//                 width={15}
//                 alt=""
//               />
//             </span>
//           </div>
//           <div className="text-center">
//             {!file &&
//             !name &&
//             !selectedAvatar &&
//             !selectedDate &&
//             !selectedMonth &&
//             !selectedYear &&
//             !selectedGender &&
//             !fileList ? (
//               <Button
//                 className="guest-btn w-auto"
//                 onClick={() => {
//                   onContactFromSubmit();
//                   modal.hide();
//                 }}
//                 style={{
//                   borderRadius: 20,
//                   padding: "10px 25px",
//                 }}
//               >
//                 Skip for Now
//               </Button>
//             ) : (
//               <Button
//                 className="w-auto"
//                 onClick={onContactFromSubmit}
//                 loading={loading}
//                 appearance="primary"
//                 style={{
//                   borderRadius: 20,
//                   background: "#F2641F",
//                   padding: "10px 25px",
//                 }}
//               >
//                 Save & Continue
//               </Button>
//             )}
//           </div>
//         </Modal.Body>
//       </Modal>
//     );
//   }
// );

// const Camera_Modal = NiceModal.create(
//   ({
//     avatar_id,
//     full_name,
//     gender_update,
//     date_of_birth,
//     profile_picture,
//     file,
//   }) => {
//     const modal = useModal();
//     let cookies = new Cookies();
//     let ACCESS_TOKEN_USER = cookies.get("login_token");
//     const videoRef = useRef(null); // To reference the video element
//     const canvasRef = useRef(null); // To reference the canvas element
//     const streamRef = useRef(null);
//     const [stream, setStream] = useState(null);
//     const [hasPermission, setHasPermission] = useState(false);
//     useEffect(() => {
//       const startCamera = async () => {
//         try {
//           const stream = await navigator.mediaDevices.getUserMedia({
//             video: true,
//           });
//           if (videoRef.current) {
//             videoRef.current.srcObject = stream;
//             setStream(stream); // Store the stream
//             setHasPermission(true);
//           }
//         } catch (err) {
//           console.error("Error accessing camera: ", err);
//           setHasPermission(false);
//         }
//       };

//       // Start the camera when the component mounts
//       startCamera();

//       // Cleanup the stream on component unmount
//       return () => {
//         if (videoRef.current && videoRef.current.srcObject) {
//           const stream = videoRef.current.srcObject;
//           // const tracks = stream.getTracks();
//           // tracks.forEach((track) => track.stop());
//         }
//       };
//     }, []);

//     const stopCamera = () => {
//       if (stream) {
//         const tracks = stream.getTracks();
//         tracks.forEach((track) => track.stop()); // Stop each track
//         // setIsCameraOn(false); // Update the camera status
//       }
//       setHasPermission(false);
//     };

//     const captureImage = () => {
//       const canvas = canvasRef.current;
//       const video = videoRef.current;

//       if (canvas && video) {
//         const context = canvas.getContext("2d");
//         // Draw the current frame of the video onto the canvas
//         context.drawImage(video, 0, 0, canvas.width, canvas.height);

//         // Convert the canvas content to a Blob (image file)
//         canvas.toBlob((blob) => {
//           // Create a File from the Blob and append it to FormData
//           const file = new File([blob], "captured-image.png", {
//             type: "image/png",
//           });
//           const imageURL = canvas.toDataURL("image/png");
//           //console.log(imageURL);
//           //  const formData = new FormData();
//           //  formData.append("image", file);
//           NiceModal.show(Update_Profile_Modal, {
//             avatar_id,
//             full_name,
//             gender_update,
//             date_of_birth,
//             profile_picture,
//             file: file,
//             preview: imageURL,
//           });
//           modal.hide();
//           stopCamera();
//           // Send the FormData to the server (for example, using fetch)
//         }, "image/png"); // You can also use 'image/jpeg' for JPEG format
//       }
//     };

//     return (
//       <Modal
//         className="rs-modal-xs"
//         open={modal.visible}
//         onClose={modal.hide}
//         onExited={modal.remove}
//       >
//         <Modal.Header>
//           <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//             Camera
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="d-flex flex-row flex-wrap">
//             <div className="w-100">
//               <video
//                 ref={videoRef}
//                 width="100%"
//                 height="100%"
//                 autoPlay
//                 style={{ background: "#333947" }}
//               />

//               <canvas
//                 ref={canvasRef}
//                 style={{ display: "none" }}
//                 width="640"
//                 height="480"
//               />
//             </div>
//           </div>
//           <div className="text-center">
//             <Button
//               onClick={captureImage}
//               appearance="primary"
//               style={{
//                 marginTop: 10,
//                 borderRadius: 20,
//                 background: "#F2641F",
//                 padding: "10px 25px",
//               }}
//             >
//               Capture
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>
//     );
//   }
// );
// const Disclaimer_Modal = NiceModal.create(
//   ({
//     avatar_id,
//     full_name,
//     gender_update,
//     date_of_birth,
//     profile_picture,
//     file,
//   }) => {
//     const modal = useModal();
//     let cookies = new Cookies();
//     let ACCESS_TOKEN_USER = cookies.get("login_token");
//     const videoRef = useRef(null); // To reference the video element
//     const canvasRef = useRef(null); // To reference the canvas element
//     const streamRef = useRef(null);
//     const [stream, setStream] = useState(null);
//     const [hasPermission, setHasPermission] = useState(false);

//     return (
//       <Modal
//         className="rs-modal-xs"
//         open={modal.visible}
//         onClose={modal.hide}
//         onExited={modal.remove}
//         backdrop="static"
//       >
//         <Modal.Header>
//           <Modal.Title className="text-white" style={{ textAlign: "center" }}>
//             Disclaimer
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="d-flex flex-row flex-wrap">
//             <div className="w-100 text-white text-center">
//               Your are currently looged in as a Guest. To participate in
//               Leaderboard contests and get special privileges, please Login and
//               get playing.
//             </div>
//           </div>
//           <div className="text-center">
//             <Button
//               onClick={modal.hide}
//               className="guest-btn me-4"
//               // appearance="primary"
//               style={{
//                 marginTop: 10,
//                 borderRadius: 20,
//                 background: "#F2641F",
//                 padding: "10px 25px",
//               }}
//             >
//               Skip
//             </Button>
//             <Button
//               onClick={() => NiceModal.show(Phone_Modal)}
//               appearance="primary"
//               style={{
//                 marginTop: 10,
//                 borderRadius: 20,
//                 background: "#F2641F",
//                 padding: "10px 25px",
//               }}
//             >
//               Login
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>
//     );
//   }
// );

const History = (history) => {
  const navigate = useNavigate();
  let cookies = new Cookies();
  let ACCESS_TOKEN_USER = cookies.get("login_token");
  const network_details = cookies.get("network_details");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [stackList, setStackList] = useState([]);
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredTitles, setFilteredTitles] = useState(data);
  const [visible, setVisible] = useState(false);
  const [search_data, setSearch_data] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [percent, setPercent] = useState(0);
  const [avatar_id, setAvatar_id] = useState(0);
  const [leader_avatar, setLeader_avatar] = useState(0);
  const [start_week, setStart_week] = useState("");
  const [end_week, setEnd_week] = useState("");
  const [username, setUsername] = useState("");
  const [fileInfo, setFileInfo] = useState(null);
  const [calender_toggle, setCalender_toggle] = useState(false);
  const {
    profile_status_load,
    add_profile_load,
    token,
    start_week_store,
    end_week_store,
  } = useStore();

  const tooltip = (
    <Tooltip>
      As a guest you are agreeing to the terms that you are 13+ years of age. To
      get the advantages and special privilages of partcipating in the Weekly
      Leaderboard Challenges, you need to Login.
    </Tooltip>
  );
  useEffect(() => {
    visitor_details();
  }, [profile_status_load]);

  const visitor_details = () => {
    Service.visitor_details()
      .then((res) => {
        let date = res?.data?.created_at;
        if (res.status == 1) {
          setFileInfo(res?.data?.profile_picture);
          setPercent(res?.data?.profile_completion_percentage);
          setAvatar_id(res?.data?.avatar_id);
          setUsername(res?.data?.full_name);
        }
      })
      .catch((e) => console.log(e));
  };

  const filteredAvatars = Avatar_data?.filter(
    (avatar) => avatar?.avatar_id === avatar_id
  );

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    leaderboard_fetch();
  }, []);

  const handleDateSelect = (date) => {
    //alert(`Selected date: ${date.toDateString()}`);
    console.log(`Selected date: ${date}`);
    setStart_week(start_week_store?.start_week_store);
    setEnd_week(end_week_store?.end_week_store);
    setTimeout(() => {
      setCalender_toggle(false);
    }, 1000);
  };

  function getStartEndOfWeekMondayUTC(date = new Date()) {
    // Get the current day of the week in UTC (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    // const dayOfWeek = date.getUTCDay();

    // // Calculate the difference in days to the start of the week (Monday) in UTC
    // const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

    // // Set the start of the week to the calculated Monday (in UTC)
    // const startOfWeek = new Date(date);
    // startOfWeek.setUTCDate(date.getUTCDate() + diffToMonday);

    // // Set the end of the week to the following Sunday (in UTC)
    // const endOfWeek = new Date(startOfWeek);
    // endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6); // Move to the following Sunday
    const dayOfWeek = date.getDay();

    // Calculate the difference in days to the start of the week (Monday) in local time
    const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek - 1;

    // Set the start of the week to the calculated Monday (in local time)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() + diffToMonday);

    // Set the end of the week to the following Sunday (in local time)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Move to the following Sunday
    return {
      startOfWeek: startOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      endOfWeek: endOfWeek.toISOString().split("T")[0], // Format as YYYY-MM-DD
      startOfWeeks: startOfWeek, // Format as YYYY-MM-DD
      endOfWeeks: endOfWeek,
    };
  }
  const { startOfWeek, endOfWeek, startOfWeeks, endOfWeeks } =
    getStartEndOfWeekMondayUTC();

  const disabledDate = (date) => {
    const today = new Date();
    return date > today; // Disable future dates
  };
  const leaderboard_fetch = () => {
    const { startOfWeek, endOfWeek } = getStartEndOfWeekMondayUTC();
    let params = {
      start_date: startOfWeek,
      end_date: endOfWeek,
    };
    Service.playing_history(params)
      .then((res) => {
        console.log(res?.data);
        if (res?.status == 1) {
          setLeaderboard(res?.data.reverse());
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    leaderboard_fetch_search(
      moment(location?.state?.start_weeks).format("YYYY-MM-DD"),
      moment(location?.state?.end_weeks).format("YYYY-MM-DD")
    );
  }, [location?.state]);

  const leaderboard_fetch_search = (start, end) => {
    let params = {
      start_date: start,
      end_date: end,
    };
    Service.playing_history(params)
      .then((res) => {
        // console.log(res?.data);
        if (res?.status == 1) {
          setLeaderboard(res?.data);
        }
      })
      .catch((e) => console.log(e));
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
          //alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const handleInputChange = (event) => {
    // setSearch(event);
    //console.log(event.target);
    if (!event) {
      setVisible(false);
      // on_game_list();
    } else if (event) {
      setVisible(true);
    }
    let params = {
      query: event,
    };
    on_search(params);
  };
  const on_search = (params) => {
    Service.search(params)
      .then((res) => {
        // console.log(res.data);
        setSearch_data(res?.data);
      })
      .catch((e) => e.message);
  };
  // const handleInputChange = (event) => {
  //   // setSearch(event);
  //   console.log(event.target);
  //   if (!event) {
  //     on_game_list();
  //   }
  //   let params = {
  //     query: event,
  //   };
  //   Service.search(params)
  //     .then((res) => {
  //       //console.log(res.data);
  //       if (res.data == null) {
  //         setData([]);
  //       }
  //       setData(res.data);
  //     })
  //     .catch((e) => e.message);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.flag === "new_game") {
      new_added_game();
    } else if (location?.state?.flag === "recent_game") {
      recent_game();
    } else if (location?.state?.flag === "updated") {
      updated_list();
    } else {
      //on_game_list();
    }
    category_techology_list();
  }, [location?.state]);

  const on_game_list = () => {
    let params;
    if (location?.state?.flag == "category") {
      params = {
        category_id: location?.state?.datas?.category_id.toString(),
      };
    } else {
      params = {
        technology_id: location?.state?.datas?.technology_id.toString(),
      };
    }
    Service.game_list(params)
      .then((res) => {
        // console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => e.message);
  };

  const updated_list = () => {
    Service.updated_games()
      .then((res) => {
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => e.message);
  };

  const new_added_game = () => {
    Service.new_added_games()
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const recent_game = () => {
    Service.recent_games()
      .then((res) => {
        //console.log(res.data);
        if (res.data == null) {
          setData([]);
        }
        setData(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const category_techology_list = () => {
    Service.category_technology_list()
      .then((res) => {
        //console.log(res.data);
        setStackList(res.data);
      })
      .catch((e) => console.log(e.message));
  };

  const handleSearch = (e) => {
    setSearchValue(e);
    if (searchValue) {
      const filteredData = data.filter((item) => {
        return Object.values(item.title)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredTitles(filteredData);
    } else {
      setFilteredTitles(data);
    }
  };
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const [activeIndex, setActiveIndex] = useState(null); // State to track active list item

  const handleClick = (index) => {
    setActiveIndex(index); // Set the clicked item's index as active
  };
  const [activeIndexTech, setActiveIndexTech] = useState(null); // State to track active list item

  const handleClickTech = (index) => {
    setActiveIndexTech(index); // Set the clicked item's index as active
  };

  const LeaderAvatars = leaderboard?.filter(
    (avatar) => avatar?.avatar_id === leader_avatar
  );
  let ACCESS_GUEST_USER = cookies.get("token");
  let start_week_show = location?.state?.data?.show_start_week;
  let end_week_show = location?.state?.data?.show_end_week;

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [previousDate, setPreviousDate] = useState(null); // To track the previously selected date

  const getDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const generateCalendarGrid = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    const startOffset = firstDayOfMonth;
    const endOffset = 6 - lastDayOfMonth;

    const placeholdersBefore = Array.from({ length: startOffset }, () => null);
    const placeholdersAfter = Array.from({ length: endOffset }, () => null);

    return [...placeholdersBefore, ...daysInMonth, ...placeholdersAfter];
  };

  // Adjusted function to get the week range (Monday to Sunday)
  const getWeekRange = (date) => {
    const dayIndex = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    // Adjust dayIndex so that Monday is the first day (0) and Sunday is the last day (6)
    const adjustedDayIndex = dayIndex === 0 ? 7 : dayIndex;

    // Start of the week (Monday)
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - adjustedDayIndex);
    startOfWeek.setHours(0, 0, 0, 0); // Reset time to start of the day (00:00:00)

    // End of the week (Sunday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Sunday is 6 days after Monday
    endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day (23:59:59)

    return { startOfWeek, endOfWeek };
  };

  const isDateInWeek = (date, weekRange) => {
    return date >= weekRange.startOfWeek && date <= weekRange.endOfWeek;
  };

  const getCurrentWeekRange = () => {
    return getWeekRange(new Date()); // Using today's date to calculate the current week
  };

  const handleDateClick = (date) => {
    if (selectedDate) setPreviousDate(selectedDate);
    setSelectedDate(date);
    //if (date) onDateSelect(date);
    // Get the week range for the clicked date
    const { startOfWeek, endOfWeek } = getWeekRange(date);

    // Pass the start and end of week to the console
    leaderboard_fetch_search(
      moment(startOfWeek).format("YYYY-MM-DD"),
      moment(endOfWeek).format("YYYY-MM-DD")
    );
    setStart_week(startOfWeek);
    setEnd_week(endOfWeek);
    setCalender_toggle(false);
    console.log(
      "Start of Week singleton: ",
      moment(startOfWeek).format("YYYY-MM-DD")
    );
    console.log("End of Week singleton: ", endOfWeek.toLocaleDateString());
  };

  const calendarGrid = generateCalendarGrid(currentDate);
  const selectedWeekRange = selectedDate ? getWeekRange(selectedDate) : null;
  const previousWeekRange = previousDate ? getWeekRange(previousDate) : null;
  const currentWeekRange = getCurrentWeekRange();

  return (
    <div>
      <Header onPress={toggleMenu} />
      {/* <header>
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col col-lg-3  text-center text-lg-start ps-0">
              <div
                className={`d-block d-lg-none toggle ${
                  isActive ? "active" : ""
                }`}
                onClick={toggleMenu}
              >
                {isActive ? (
                  <img
                    src={require("./skins/nav-close.png")}
                    alt="Close Navigation"
                  />
                ) : (
                  <img
                    src={require("./skins/nav-open.png")}
                    alt="Open Navigation"
                  />
                )}
              </div>
              <img
                className="logo"
                src={Logo}
                alt=""
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              />
              <div className="searchContainer">
                <InputGroup>
                  <Input
                    //onChange={handleSearch}
                    //value={searchValue}
                    // value={search}
                    onChange={handleInputChange}
                    className="search-box"
                    placeholder={"Search Game"}
                  />
                  <InputGroup.Addon style={{ top: "0px" }}>
                    <img src={require("./skins/search.png")} alt="" />
                  </InputGroup.Addon>
                </InputGroup>
                {visible && (
                  <div className="custom-picker-popup">
                    {search_data?.map((item) => {
                      return (
                        <div
                          className="d-flex align-items-center searchItem "
                          onClick={() => {
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            });
                            // window.location.reload();
                          }}
                        >
                          <img
                            onClick={() => {
                              navigate(`/game/${item?.id}/${item?.title}`, {
                                state: {
                                  datas: item,
                                },
                              });
                              // window.location.reload();
                            }}
                            style={{
                              borderRadius: 5,
                              margin: 5,
                              objectFit: "cover",
                            }}
                            src={item.cover_picture}
                            height={"40px"}
                            width={"40px"}
                          />
                          <div className="ms-2">
                            {item.title}
                            <div className="d-flex gameInfo" ref={wrapperRef}>
                              <small>{item?.category_names}</small>&nbsp; |
                              &nbsp;
                              <small>{item?.technology_names}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="col-4 text-end d-flex justify-content-end position-relative p-0">
              <div className="leaderboard">
                <img
                  className="w-100"
                  onClick={() => {
                    if (ACCESS_TOKEN_USER) {
                      navigate("/leaderboard");
                    } else if (!ACCESS_TOKEN_USER) {
                      NiceModal.show(Phone_Modal);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    opacity: !ACCESS_TOKEN_USER ? 0.2 : 1,
                  }}
                  src={require("../assets/leaderboard.png")}
                />
              </div>

              {!ACCESS_TOKEN_USER ? (
                <>
                  {token ? (
                    <span
                      className="text-white headerUsername d-none d-md-flex flex-nowrap align-items-center"
                      style={{ right: "14px", left: "unset" }}
                    >
                      Logged in as : Guest
                      <Whisper
                        placement="bottomEnd"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={tooltip}
                      >
                        <img
                          className="ms-2"
                          src={require("../assets/info-icon.png")}
                          width={12}
                          alt=""
                        />
                      </Whisper>
                    </span>
                  ) : ACCESS_GUEST_USER ? (
                    <span
                      className="text-white headerUsername d-none d-md-flex flex-nowrap align-items-center"
                      style={{ right: "14px", left: "unset" }}
                    >
                      Logged in as : Guest
                      <Whisper
                        placement="bottomEnd"
                        controlId="control-id-click"
                        trigger="click"
                        speaker={tooltip}
                      >
                        <img
                          className="ms-2"
                          src={require("../assets/info-icon.png")}
                          width={12}
                          alt=""
                        />
                      </Whisper>
                    </span>
                  ) : null}
                  <Button
                    onClick={() => {
                      NiceModal.show(Phone_Modal);
                      // modal.hide()
                    }}
                    className="loginBtn"
                  >
                    <img
                      className="d-block d-sm-none"
                      src={require("../assets/user.png")}
                      width={15}
                      alt=""
                      srcset=""
                    />
                    <span className="d-none d-sm-block">Login</span>
                  </Button>
                </>
              ) : (
                <div className="d-flex align-items-center position-relative">
                  <Avatar
                    src={
                      fileInfo ||
                      (filteredAvatars.length > 0 &&
                        require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
                    }
                    onClick={() => {
                      NiceModal.show(Profile_edit_Modal, { navigate });
                    }}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                    circle
                  />
                  <span
                    className="text-white headerUsername d-none d-md-block"
                    style={{ left: "-80px" }}
                  >
                    {username?.length > 17
                      ? username?.substring(0, 17 - 1) + ""
                      : username}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </header> */}
      <div className="container-fluid pl-80 d-flex flex-column justify-content-between align-items-none h-100">
        <div className="row ">
          {/* NAVIGATION */}
          <div className={`navigation ${isActive ? "active" : ""}`}>
            <p
              className="m-2 ps-5 d-flex align-items-center d-md-none"
              style={{ color: "#f2641f" }}
            >
              {ACCESS_TOKEN_USER
                ? username?.length > 17
                  ? username?.substring(0, 17 - 1) + ""
                  : username
                : "Guest User"}

              {!ACCESS_TOKEN_USER && (
                <Whisper
                  placement="bottom"
                  controlId="control-id-click"
                  trigger="click"
                  speaker={tooltip}
                >
                  <img
                    className="ms-2"
                    src={require("../assets/info-icon.png")}
                    width={15}
                    alt=""
                  />
                </Whisper>
              )}
            </p>
            <ul>
              <li style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/home.png")}
                  />
                </span>
                <span className="title">Home</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/new_games", {
                    state: {
                      flag: "new_game",
                      name: "New Game",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/star.png")}
                  />
                </span>
                <span className="title">New Games</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/recent_games", {
                    state: {
                      flag: "recent_game",
                      name: "Recently Played",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/recent.png")}
                  />
                </span>
                <span className="title">Recently Played</span>
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/updated", {
                    state: {
                      flag: "updated",
                      name: "Updated",
                    },
                  })
                }
              >
                <span className="icon">
                  <img
                    style={{ cursor: "pointer" }}
                    src={require("./skins/updated.png")}
                  />
                </span>
                <span className="title">Updated</span>
              </li>
            </ul>

            <ul className="mt-5">
              {stackList?.categories?.map((item, index) => {
                //console.log(item.game_img);
                return (
                  <li
                    key={index}
                    className={activeIndex === index ? "active" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      logEvent(analytics, "games_most_categories_visited", {
                        category_id: item?.category_id_,
                        category_name: item?.category_name,
                      });
                      logEvent(analytics, "mostgames_categories_countries", {
                        category_name: item?.category_name,
                        country: network_details?.country_name,
                        city: network_details?.city,
                      });
                      navigate(
                        `/category/${item?.category_id}/${item?.category_name}`,
                        {
                          state: {
                            datas: item,
                            name: item?.category_name,
                            flag: "category",
                          },
                        }
                      );
                      handleClick(index);
                    }}
                  >
                    <span className="icon">
                      <img
                        key={index}
                        style={{ cursor: "pointer" }}
                        src={item?.icon}
                        className="color1"
                      />
                    </span>
                    <span className="title">{item.category_name}</span>
                    {/* <p style={{ color: "wheat" }}>{item.category_name}</p> */}
                  </li>
                );
              })}
            </ul>

            <ul className="mt-5">
              {stackList?.technologies?.map((item, index) => {
                //console.log(item.game_img);
                return (
                  <li
                    // key={index}
                    // className={activeIndex === index ? "active" : ""}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      logEvent(analytics, "games_most_technologies_visited", {
                        technology_id: item?.technology_id,
                        technology_name: item?.technology_name,
                      });
                      logEvent(analytics, "mostgames_technology_countries", {
                        technology_name: item?.technology_name,
                        country: network_details?.country_name,
                        city: network_details?.city,
                      });
                      navigate(
                        `/technology/${item?.technology_id}/${item?.technology_name}`,
                        {
                          state: {
                            datas: item,
                            name: item?.technology_name,
                            flag: "technology",
                          },
                        }
                      );
                      //handleClickTech(index);
                    }}
                  >
                    <span className="icon">
                      <img
                        key={index}
                        style={{ cursor: "pointer" }}
                        src={item?.icon}
                        className="color1"
                      />
                    </span>
                    <span className="title">{item.technology_name}</span>
                  </li>
                );
              })}
            </ul>

            <ul className="otherLink">
              <li
                onClick={() =>
                  navigate("/terms_and_conditions", {
                    state: {
                      flag: "t&c",
                      name: "Terms and Conditions",
                    },
                  })
                }
              >
                <span>Terms & Conditions</span>
              </li>
              <li
                onClick={() =>
                  navigate("/privacy_policy", {
                    state: {
                      flag: "policy",
                      name: "Privacy Policy",
                    },
                  })
                }
              >
                <span>Privacy</span>
              </li>
            </ul>
          </div>
          {/* NAVIGATION */}
          {/* PAGE CONTENT */}
          <div className="col mt-3">
            <div className="row d-block d-lg-none">
              <div className="col-12 mb-3 d-flex justify-content-center">
                <InputGroup>
                  <Input
                    //onChange={handleSearch}
                    //value={searchValue}
                    // value={search}
                    onChange={handleInputChange}
                    className="search-box"
                    placeholder={"Search Game"}
                  />
                  <InputGroup.Addon style={{ top: "0px" }}>
                    {/* <SearchIcon className="icon_awesome_search1" color="#FFFFFF" /> */}
                    <img src={require("./skins/search.png")} alt="" />
                  </InputGroup.Addon>
                </InputGroup>
                {visible && (
                  <div className="custom-picker-popup-mobile">
                    {search_data?.map((item) => {
                      return (
                        <div
                          className="d-flex align-items-center searchItem"
                          onClick={() => {
                            navigate(`/game/${item?.id}/${item?.title}`, {
                              state: {
                                datas: item,
                              },
                            });
                            //window.location.reload();
                          }}
                        >
                          <img
                            style={{
                              borderRadius: 5,
                              margin: 5,
                              objectFit: "cover",
                            }}
                            src={item.cover_picture}
                            height={"40px"}
                            width={"40px"}
                          />
                          <div className="ms-2">
                            {item.title}
                            <div className="d-flex gameInfo">
                              <small>{item?.category_names}</small>&nbsp; |
                              &nbsp;
                              <small>{item?.technology_names}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-3"></div>
              <div className="col-12 ">
                <div className="dark-bg h-100 px-3">
                  <div className="row m-0">
                    <div className="col mb-2 d-flex align-items-center justify-content-between">
                      <h5 className="leaderboardTitle">Playing History</h5>
                      <span className="date">
                        {/* <DateRangePicker
                          editable={false}
                          disabledDate={disabledDate}
                          style={{ background: "none" }}
                          defaultValue={[
                            new Date(startOfWeeks),
                            new Date(endOfWeeks),
                            // new Date(start_week_show),
                            // new Date(end_week_show),
                          ]}
                          oneTap
                          showOneCalendar
                          hoverRange="week"
                          isoWeek
                          placement="autoVerticalEnd"
                          onChange={(e) => {
                            leaderboard_fetch_search(
                              moment(e[0]).format("YYYY-MM-DD"),
                              moment(e[1]).format("YYYY-MM-DD")
                            );
                            setStart_week(moment(e[0].toISOString()));
                            setEnd_week(moment(e[1].toISOString()));
                          }}
                          ranges={[]}
                        /> */}
                        {/* {calender_toggle && (
                          <CCalendar onDateSelect={handleDateSelect} />
                        )} */}
                        {calender_toggle && (
                          <div className="calendar">
                            <div className="calendar-header text-white">
                              <button
                                onClick={() =>
                                  setCurrentDate(
                                    new Date(
                                      currentDate.getFullYear(),
                                      currentDate.getMonth() - 1,
                                      1
                                    )
                                  )
                                }
                              >
                                <i class="fa-solid fa-chevron-left"></i>
                              </button>
                              <h2>
                                {currentDate.toLocaleDateString("en-US", {
                                  month: "short",
                                  year: "numeric",
                                })}
                              </h2>
                              <button
                                onClick={() =>
                                  setCurrentDate(
                                    new Date(
                                      currentDate.getFullYear(),
                                      currentDate.getMonth() + 1,
                                      1
                                    )
                                  )
                                }
                              >
                                <i class="fa-solid fa-chevron-right"></i>
                              </button>
                            </div>
                            <div className="calendar-grid">
                              {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map(
                                (day) => (
                                  <div
                                    key={day}
                                    className="calendar-day-header text-white"
                                  >
                                    {day}
                                  </div>
                                )
                              )}
                              {calendarGrid.map((day, index) => (
                                <div
                                  key={index}
                                  className={`calendar-day ${
                                    day &&
                                    selectedWeekRange &&
                                    isDateInWeek(day, selectedWeekRange) &&
                                    "highlighted-week-current"
                                  } ${
                                    day &&
                                    currentWeekRange &&
                                    isDateInWeek(day, currentWeekRange) &&
                                    "highlighted-week-previous"
                                  }`}
                                  onClick={() => day && handleDateClick(day)}
                                >
                                  <div
                                    key={index}
                                    className={`${
                                      day &&
                                      currentWeekRange &&
                                      isDateInWeek(day, currentWeekRange) &&
                                      "highlighted-week-previous"
                                    }`}
                                  >
                                    {day ? day.getDate() : ""}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setCalender_toggle(!calender_toggle)}
                          className="orange_sec"
                        >
                          {`${moment(
                            location?.state?.start_weeks || startOfWeek
                          ).format("DD MMM")} - ${moment(
                            location?.state?.end_weeks || endOfWeek
                          ).format("DD MMM, YYYY")}`}
                          <span
                            // className="editLink d-flex align-items-center"
                            style={{ pointerEvents: "none", cursor: "pointer" }}
                          >
                            <img
                              style={{ marginLeft: 6 }}
                              src={require("../assets/down-arrow.png")}
                              width={14}
                              alt=""
                            />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="col-12">
                      <div className="scrollTable">
                        <table className="leaderboardTable w-100 text-white">
                          {/* <tr
                            style={{
                              backgroundColor: "#3d4063",
                              fontWeight: "400",
                            }}
                          >
                            <th className="text-center p-2">ID</th>
                            <th className="text-start ps-5 p-2">Game Name</th>
                            <th className="text-start p-2 text-nowrap">
                              Date & Time
                            </th>
                            <th className="text-start p-2">Score</th>
                            <th className="text-end p-2"> Point</th>
                          </tr> */}
                          {leaderboard?.map((item, index) => {
                            return (
                              <tr>
                                <td style={{ width: "50px" }}>{index + 1}</td>
                                <td style={{ width: "400px" }}>
                                  <div className=" d-flex align-items-center text-nowrap">
                                    <Avatar
                                      src={
                                        item.Game_image ||
                                        (filteredAvatars.length > 0 &&
                                          require(`../assets/avatars/${filteredAvatars[0].avatar_icon}`))
                                      }
                                      className="me-3"
                                      circle
                                    />
                                    {item?.Game_name}
                                  </div>
                                </td>
                                <td>
                                  {`${moment(item?.Playing_date_time).format(
                                    "DD MMM, YYYY"
                                  )}`}
                                </td>
                                <td>
                                  {moment(item?.Playing_date_time).format(
                                    "HH:MM"
                                  )}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {`Score - ${item?.Score}`}
                                  </div>
                                </td>
                                <td style={{ width: "100px" }}>
                                  <div className="d-flex align-items-center justify-content-end">
                                    <img
                                      src={require("../assets/star.png")}
                                      width={15}
                                      alt=""
                                      className="me-1"
                                    />
                                    {item?.Equivalent_Score}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                      {leaderboard?.length == 0 && (
                        <div className="row">
                          <div className="col">
                            <h5 className="text-center text-white">
                              Sorry! You have not played any game. Start
                              Playing.
                            </h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-fluid border_top ">
            <div className="row mt-3">
              <div className="col-12 text-center">
                {/* <p className="d-block d-lg-flex align-items-center justify-content-center">
                  Development Partner
                  <img src={require("./skins/rat.png")} alt="" />
                  <a href="https://redappletech.com/" target="_blank">
                    <span>Red Apple Technology </span>
                  </a>
                  &nbsp; | Learning Partner
                  <img src={require("./skins/ral.png")} alt="" />
                  <a href="https://redapplelearning.in/" target="_blank">
                    <span>Red Apple Learning</span>
                  </a>
                </p> */}
                <p>
                  Copyright &copy;{" "}
                  <a
                    className="text-white"
                    href="https://thinktrekent.in/"
                    target="_blank"
                  >
                    Thinktrek Entertainment
                  </a>
                </p>
                <ul className="footerlist">
                  <li
                    onClick={() =>
                      navigate("/terms_and_conditions", {
                        state: {
                          flag: "t&c",
                          name: "Terms and Conditions",
                        },
                      })
                    }
                  >
                    <span>Terms & Conditions</span>
                  </li>
                  <li
                    onClick={() =>
                      navigate("/privacy_policy", {
                        state: {
                          flag: "policy",
                          name: "Privacy Policy",
                        },
                      })
                    }
                  >
                    <span>Privacy</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="content-container12">
        <div className="group_2642112">
          {/* {searchValue.length > 1
            ? filteredTitles?.map((item, index) => {
                //console.log(item);
                return (
                  <div>
                    <img
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/game/${item?.id}/${item?.title}`, {
                          state: {
                            datas: item,
                          },
                        })
                      }
                      src={item?.cover_picture}
                      className="connect_the_ball_ek11"
                    />
                  </div>
                );
              })
            : data?.map((item, index) => {
                //console.log(item);
                return (
                  <div>
                    <img
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/game/${item?.id}/${item?.title}`, {
                          state: {
                            datas: item,
                          },
                        })
                      }
                      src={item?.cover_picture}
                      className="connect_the_ball_ek11"
                    />
                  </div>
                );
              })} */}
        </div>
      </div>
    </div>
  );
};

export default History;
